import styled from "@emotion/styled";
import React, { MouseEventHandler } from "react";
import ico_remove_black from "@src/assets/ico_remove_black.svg";
import { transferToNative } from "@src/lib/WebAppBridge";
import { keyframes } from "@emotion/react";
import { useDepositDetail } from "@src/hooks/item/useDepositDetail";
import { useParams, useSearchParams } from "react-router-dom";

function DepositDetail() {
    const params = useParams();
    const [searchParam] = useSearchParams();
    const { data } = useDepositDetail({ itemId: params.id! });

    const occupier = searchParam.get("occupier");

    const onClickExitButton: MouseEventHandler<HTMLImageElement> = () => {
        transferToNative("", "closeWebView");
    };

    return (
        <>
            <DepositDetailSection>
                <ExitIcon src={ico_remove_black} onClick={onClickExitButton}></ExitIcon>
                <Title>보증금 상세</Title>
                <TableHead>
                    <Td scope="col">임차인</Td>
                    <Td scope="col">|</Td>
                    <Td scope="col">점유부분</Td>
                    <Td scope="col">|</Td>
                    <Td scope="col">보증금 상세</Td>
                </TableHead>
                <TableContent>
                    {data?.data.lesseesDepositDetails.map((item) => (
                        <Tr key={Date.now()} className={occupier === item.occupier ? "fade-in" : ""}>
                            <Td>{item.occupier}</Td>
                            <Td className="col-padding"></Td>
                            <Td>{item.occuPart}</Td>
                            <Td className="col-padding"></Td>
                            <Td>{item.depositDetails}</Td>
                        </Tr>
                    ))}
                </TableContent>
            </DepositDetailSection>
        </>
    );
}

const FadeInAnimation = keyframes`
    0% {
        background-color: #DFF0FF;
    }
    100% {
        background-color: #fff;
    }
`;

const DepositDetailSection = styled.section``;
const Title = styled.h1`
    font-size: 18px;
    font-weight: bold;
    line-height: 56px;
    text-align: center;
`;
const ExitIcon = styled.img`
    position: absolute;
    top: 16px;
    right: 16px;
`;
const TableHead = styled.div`
    display: flex;
    justify-content: space-evenly;
    background-color: #17366d;
    color: #fff;
    padding: 4px 14px 4px 14px;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    text-align: center;
`;
const TableContent = styled.div`
    height: calc(100vh - 56px);
    overflow-y: scroll;
    padding-bottom: 120px;

    .fade-in {
        animation: ${FadeInAnimation} 2s;
    }
`;
const Tr = styled.tr`
    display: flex;
    justify-content: space-evenly;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;
    margin: 14px;

    .col-padding {
        border: 0;
    }
`;
const Td = styled.td`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.6px solid #8d8d8d;

    &:nth-of-type(1) {
        width: 25%;
    }

    &:nth-of-type(3) {
        width: 25%;
    }

    &:nth-of-type(5) {
        width: 50%;
        padding: 4px;
    }

    &[scope="col"] {
        border: 0;
    }
`;

export default DepositDetail;
