import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import ConvertPyeongButton from "@src/components/button/ConvertPyeongButton";
import LineChart from "@src/components/chart/LineChart";
import Table from "@src/components/table/Table";
import Title from "@src/components/title/Title";
import { transferToNative } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import convertStrToPrice from "@src/util/convertStrToPrice";
import { useFindOneRoadWidth } from "@src/hooks/map/useFindRoadWidth";
import stateLandServiceShowBox from "@src/assets/fieldDetail/stateLandServiceShowBox.png";
import { landChangeHeaderInLandDetail, landPriceHeaderInLandDetail } from "./getColums";
import { useFindOneStateLandInfo } from "@src/hooks/stateLand/useGetStateLand";
import { format } from "date-fns";
import { isShowLabel } from "@src/util/isShowBottomSheetLabel";
import callIcon from "@src/assets/basicIcon/callIcon.svg";

interface landDetailProps {
    data: any;
    pnu: string;
    landInfoRef: any;
    stateLandInfoRef: any;
    areaSize?: { pyong: string; square: string };
    fieldJimok: string;
    eumMapLinks: any;
}

//* 국유지
const onbidURL = "https://www.onbid.co.kr/op/ipa/gvwsmn/governmentOwnershipPropertyDetail.do?cltrNo=";
//* 공유지
const onbidURL2 = "https://www.onbid.co.kr/op/ipa/infomn/informationPublicPropertyDetail.do?searchDetailId=";

const highlightTest = /^(대부\/매각대상|매각대상|매각\(입찰\)가능재산|대부\(입찰\)가능재산|대부대상)/;
const rentTest = /(대부\(입찰\)가능재산|대부대상)/;
const allTest = /(대부\/매각대상)/;

function LandDetail({ data, pnu, landInfoRef, stateLandInfoRef, areaSize, fieldJimok, eumMapLinks }: landDetailProps) {
    const INVALID = "-";
    let { base, landUsePlan, officialPrice } = data;
    const [sizeUnit, setSizeUnit] = useState<"pyeong" | "meter">("meter");
    const handleCovert = (type: "pyeong" | "meter") => {
        setSizeUnit(type === "meter" ? "pyeong" : "meter");
    };
    const dateConvert = (date: any) => {
        if (typeof date === "string") {
            if (date !== " ") return date.replaceAll("-", ".");
        }
        return INVALID;
    };
    const stringValidChecker = (input: any): string => {
        if (typeof input === "string") {
            if (input !== " " && input !== "") return input;
            return INVALID;
        }
        return INVALID;
    };
    //토지이용계획
    const LandChangeTableHeader = landChangeHeaderInLandDetail();
    const LandPriceTableHeader = landPriceHeaderInLandDetail(sizeUnit);
    const [landChangeAllTable, setLandChangeAllTable] = useState([{ date: "1990.11.11", reason: "" }]);
    const [landChangeViewingData, setLandChangeViewingData] = useState<any[]>([]);

    //개별공시지가
    const [officialPriceChart, setOfficialPriceChart] = useState<{
        xAxis: string[];
        chartData: string[];
    }>({ xAxis: [], chartData: [] });
    const [officialPriceTable, setOfficialPriceTable] = useState([{ stdr: "1990년 1월", pblntfPclnd: "1,800,000원" }]);
    const [officialPriceViewingData, setOfficialPriceViewingData] = useState<any[]>([]);

    const officialPriceChartData = () => {
        if (!officialPrice?.length) {
            setOfficialPriceChart({
                xAxis: [],
                chartData: [],
            });
        }
        let xAxis: string[] = [];
        let chartData: string[] = [];
        officialPrice.map((item: any) => {
            xAxis.push(item.stdrYear);
            chartData.push(item.pblntfPclnd);
        });

        setOfficialPriceChart({ xAxis, chartData });
    };
    const officialPyeongPriceChartData = () => {
        if (!officialPrice?.length) {
            setOfficialPriceChart({
                xAxis: [],
                chartData: [],
            });
        }
        let xAxis: string[] = [];
        let chartData: string[] = [];
        officialPrice.map((item: any) => {
            xAxis.push(item.stdrYear);
            chartData.push(Math.round(+item.pblntfPclndPyeong).toString());
        });

        setOfficialPriceChart({ xAxis, chartData });
    };
    const officialPriceTableData = () => {
        if (!officialPrice?.length) setOfficialPriceTable([{ stdr: "1990년 1월", pblntfPclnd: "1,800,000원" }]);
        let result: any[] = [];
        officialPrice.map((item: any) => {
            const { stdrYear, stdrMt, pblntfPclnd } = item;
            result.push({
                stdr: `${stdrYear}.${stdrMt}`,
                pblntfPclnd: `${convertStrToPrice(pblntfPclnd)}원`,
            });
        });

        setOfficialPriceTable(result.reverse());
        setOfficialPriceViewingData(result?.slice(0, 5) ?? []);
    };
    const officialPyeongPriceTableData = () => {
        if (!officialPrice?.length) setOfficialPriceTable([{ stdr: "1990년 1월", pblntfPclnd: "1,800,000원" }]);
        let result: any[] = [];
        officialPrice.map((item: any) => {
            const { stdrYear, stdrMt, pblntfPclndPyeong } = item;
            result.push({
                stdr: `${stdrYear}.${stdrMt}`,
                pblntfPclnd: `${convertStrToPrice(pblntfPclndPyeong)}원`,
            });
        });

        setOfficialPriceTable(result.reverse());
        setOfficialPriceViewingData(result?.slice(0, 5) ?? []);
    };

    //* roadWidth
    const { data: roadWidth, refetch: refetchRoadWidth } = useFindOneRoadWidth(pnu);

    //* 국공유지 정보
    const { data: stateLandInfo, refetch: refetchStateLandInfo } = useFindOneStateLandInfo(pnu);

    const onClickToOnbid = () => {
        if (stateLandInfo) {
            const newTitle = rentTest.test(stateLandInfo?.status ?? "")
                ? "대부(임대) 신청하기"
                : allTest.test(stateLandInfo?.status ?? "")
                ? "대부/매각 신청하기"
                : "불하(매각) 신청하기";
            const newUrl = stateLandInfo?.propType?.includes("공유")
                ? `${onbidURL2}${stateLandInfo.kamcoId}`
                : `${onbidURL}${stateLandInfo.kamcoId}`;
            const params = {
                title: newTitle,
                url: newUrl,
            };
            transferToNative(JSON.stringify(params), "openExternalMap");
        }
    };

    useEffect(() => {
        refetchStateLandInfo();
        if (base.lndcgrCodeNm === "도로") {
            refetchRoadWidth();
        }
    }, [data]);

    useEffect(() => {
        officialPyeongPriceChartData();
        officialPyeongPriceTableData();
    }, [officialPrice]);
    useEffect(() => {
        setLandChangeViewingData(landUsePlan.table.slice(0, 5));
        setLandChangeAllTable(landUsePlan.table);
    }, [landUsePlan]);

    useEffect(() => {
        if (sizeUnit === "meter") {
            officialPriceChartData();
            officialPriceTableData();
        } else {
            officialPyeongPriceChartData();
            officialPyeongPriceTableData();
        }
    }, [sizeUnit]);

    return (
        <div css={landDetailWrap} id={"land"}>
            <section>
                <div className="titleBoxWithButton">
                    <Title title={"토지정보"} size={"large"} />
                    <ConvertPyeongButton handleConvert={handleCovert} />
                </div>
                <ul className="base" ref={landInfoRef}>
                    <li>
                        <span className="name">지목</span>
                        <span className="value">{stringValidChecker(fieldJimok)}</span>
                    </li>
                    {base?.lndcgrCodeNm === "도로" && (
                        <li>
                            <p className="newBox">
                                <span className="name newName">도로폭</span>
                                {/* <span className="new">NEW</span> */}
                            </p>
                            <span className="value">{roadWidth ? `${roadWidth}m` : INVALID}</span>
                        </li>
                    )}
                    <li>
                        <span className="name">면적</span>
                        <span className="value">
                            {sizeUnit === "meter"
                                ? `${
                                      isShowLabel(base?.lndpclAr?.square ?? "")
                                          ? base?.lndpclAr?.square
                                          : isShowLabel(areaSize?.square ?? "")
                                          ? areaSize?.square
                                          : "-"
                                  }`
                                : `${
                                      isShowLabel(base?.lndpclAr?.pyong ?? "")
                                          ? base?.lndpclAr?.pyong
                                          : isShowLabel(areaSize?.pyong ?? "")
                                          ? areaSize?.pyong
                                          : "-"
                                  }`}
                            {/* {sizeUnit === "meter"
                                ? `${
                                      isShowLabel(areaSize?.square ?? "")
                                          ? areaSize?.square
                                          : isShowLabel(base?.lndpclAr?.square ?? "")
                                          ? base?.lndpclAr?.square
                                          : "-"
                                  }`
                                : `${
                                      isShowLabel(areaSize?.pyong ?? "")
                                          ? areaSize?.pyong
                                          : isShowLabel(base?.lndpclAr?.pyong ?? "")
                                          ? base?.lndpclAr?.pyong
                                          : "-"
                                  }`} */}
                        </span>
                    </li>
                    <li>
                        <span className="name">이용상황</span>
                        <span className="value">{stringValidChecker(base.ladUseSittnNm)}</span>
                    </li>
                    <li>
                        <span className="name">용도지역</span>
                        <span className="value">{stringValidChecker(base.use)}</span>
                    </li>
                    <li>
                        <p className="newBox">
                            <span className="name newName">고도</span>
                            {/* <span className="new">NEW</span> */}
                        </p>
                        <span className="value">{stringValidChecker(base.altitude)}</span>
                    </li>
                    {/* <li>
                        <span className="name">공시지가</span>
                        <p>
                            <span className="value blue">
                                {convertStrToPrice(sizeUnit === "meter" ? base.pblntfPclnd : base.pblntfPclndPyeong)}원 (
                                {sizeUnit === "meter" ? "㎡" : "평"}당)
                            </span>
                            <span className="date">기준일자 {dateConvert(base.lastUpdtDt)}</span>
                        </p>
                    </li> */}
                    <li>
                        <span className="name">지형</span>
                        <span className="value">{stringValidChecker(base.tpgrphHgCodeNm)}</span>
                    </li>
                    <li>
                        <span className="name">형상</span>
                        <span className="value">{stringValidChecker(base.tpgrphFrmCodeNm)}</span>
                    </li>
                    <li>
                        <span className="name">도로</span>
                        <span className="value">{stringValidChecker(base.roadSideCodeNm)}</span>
                    </li>
                    <li>
                        <span className="name">소유구분</span>
                        <span className="value">
                            {stringValidChecker(base.posesnSeCodeNm)}
                            {base.cnrsPsnCo && stringValidChecker(base.cnrsPsnCo)}
                        </span>
                    </li>
                    <li>
                        <span className="name">소유권변동일</span>
                        <span className="value">{dateConvert(base.ownshipChgDe)}</span>
                    </li>
                    <li>
                        <span className="name">소유권변동원인</span>
                        <span className="value">{stringValidChecker(base.ownshipChgCauseCodeNm)}</span>
                    </li>
                </ul>
            </section>
            {stateLandInfo?.isShow && (
                <section style={{ position: "relative" }}>
                    <div css={stateLandScrollBoxStyle} ref={stateLandInfoRef} />
                    <p css={stateLandTitleStyle}>
                        국∙공유지 정보
                        {!!stateLandInfo?.updatedAt && (
                            <span>{format(new Date(stateLandInfo?.updatedAt ?? new Date()), "yy년 M월 d일")} 업데이트됨</span>
                        )}
                    </p>
                    <div css={stateLandBoxStyle(highlightTest.test(stateLandInfo?.status ?? ""))}>
                        <div className="box">
                            <p className="title">소유구분</p>
                            <p className="desc">{stateLandInfo?.ownedBy}</p>
                        </div>
                        <div className="box">
                            <p className="title">관리상태</p>
                            <p className="desc">
                                <span className="status">{stateLandInfo?.status ?? "-"}</span>
                            </p>
                        </div>
                        <div className="box alignStart" css={{ height: "fit-content !important" }}>
                            <p className="title">재산관리기관</p>
                            <p className="desc wrapStyle">
                                {stateLandInfo?.managedBy ?? "-"}
                                <br />
                                <span>
                                    <img src={callIcon} alt="call" width={16} />
                                    <a href={`tel://${stateLandInfo?.contact}`}>{stateLandInfo?.contact}</a>
                                    {stateLandInfo?.contactSuffix}
                                </span>
                            </p>
                        </div>
                        {!stateLandInfo?.isPremeumUser && (
                            <img
                                src={stateLandServiceShowBox}
                                alt=""
                                className="lockImg"
                                width={180}
                                height={112}
                                onClick={() => {
                                    transferToNative("msgaapp://deeplink/product/state_land", "onClickBottomButton");
                                }}
                            />
                        )}
                        {highlightTest.test(stateLandInfo?.status ?? "") && (
                            <button css={stateLandButtonStyle} onClick={onClickToOnbid}>
                                {rentTest.test(stateLandInfo?.status ?? "")
                                    ? "대부(임대) 신청하기"
                                    : allTest.test(stateLandInfo?.status ?? "")
                                    ? "대부/매각 신청하기"
                                    : "불하(매각) 신청하기"}
                            </button>
                        )}
                    </div>
                </section>
            )}
            <section>
                <Title title={"토지이용계획"} size={"large"} />
                <ul>
                    <li>
                        <Title title={"국토의 계획 및 이용에 관한 법률"} size={"small"} />
                    </li>
                    <li>
                        <span className="name">포함</span>
                        <span className="value">{landUsePlan.landLaw.inclusion}</span>
                    </li>
                    <li>
                        <span className="name">저촉</span>
                        <span className="value">{landUsePlan.landLaw.conflict}</span>
                    </li>
                    <li>
                        <span className="name">접함</span>
                        <span className="value">{landUsePlan.landLaw.connect}</span>
                    </li>
                </ul>
                <ul
                    style={{
                        borderTop: `6px solid ${Common.colors.grey02}`,
                        borderBottom: `6px solid ${Common.colors.grey02}`,
                        paddingTop: "14px",
                        paddingBottom: "14px",
                        marginBottom: "0px",
                    }}
                >
                    <li>
                        <Title title={"기타법률"} size={"small"} />
                    </li>
                    <li>
                        <span className="name">포함</span>
                        <span className="value">{landUsePlan.etcLaw.inclusion}</span>
                    </li>
                    <li>
                        <span className="name">저촉</span>
                        <span className="value">{landUsePlan.etcLaw.conflict}</span>
                    </li>
                    <li>
                        <span className="name">접함</span>
                        <span className="value">{landUsePlan.etcLaw.connect}</span>
                    </li>
                </ul>
                <div className="titleBoxWithButton indLandPrice">
                    <Title title="개별공시지가" size={"large"} />
                    <ConvertPyeongButton handleConvert={handleCovert} />
                </div>
                {officialPriceChart.chartData.length ? (
                    <>
                        <div style={{ padding: "0", marginBottom: "0" }}>
                            <LineChart
                                xAxis={officialPriceChart.xAxis}
                                chartData={officialPriceChart.chartData}
                                isPyeong={sizeUnit === "pyeong"}
                            />
                        </div>
                        <div css={landPriceTableWrap({ tableWrap })}>
                            <Title title="연도별 공시지가" size={"small"} />
                            <Table
                                color={"gray"}
                                columns={LandPriceTableHeader}
                                data={officialPriceViewingData}
                                dataList={officialPriceTable}
                                setData={setOfficialPriceViewingData}
                            />
                        </div>
                    </>
                ) : (
                    <div css={nullBox} style={{ paddingBottom: "24px" }}>
                        개별공시지가 정보가 존재하지 않습니다
                    </div>
                )}
            </section>
            <section>
                <div css={tableWrap}>
                    <Title title="토지 이동(변동) 사유" size={"small"} />

                    {landChangeAllTable.length !== 0 ? (
                        <Table
                            color={"gray"}
                            columns={LandChangeTableHeader}
                            data={landChangeViewingData}
                            dataList={landChangeAllTable}
                            setData={setLandChangeViewingData}
                        />
                    ) : (
                        <div css={nullBox} className={"indLandPrice"}>
                            토지 이동(변동) 사유 정보가 존재하지 않습니다
                        </div>
                    )}
                </div>

                <div className="buttons">
                    <button
                        className="greenButton"
                        onClick={() => {
                            const params = {
                                title: "토지이용계획 열람",
                                url: eumMapLinks.land_use_plan,
                            };
                            transferToNative(JSON.stringify(params), "openExternalMap");
                        }}
                    >
                        토지이용계획 열람
                    </button>
                    <button
                        className="greenButton"
                        onClick={() => {
                            const params = {
                                title: "도시계획 열람",
                                url: eumMapLinks.city_plan,
                            };
                            transferToNative(JSON.stringify(params), "openExternalMap");
                        }}
                    >
                        도시계획 열람
                    </button>
                </div>
                <div className="buttonBox">
                    <button
                        className="greenButton box"
                        onClick={() => {
                            const params = {
                                title: "씨리얼지도",
                                url: `https://seereal.lh.or.kr/SeerealMAP/seerealCommon/mainPage.do?redirect=true&pnu=${pnu}`,
                            };
                            transferToNative(JSON.stringify(params), "openExternalMap");
                        }}
                    >
                        씨리얼 지도
                    </button>
                </div>
            </section>
        </div>
    );
}

const landDetailWrap = css`
    padding: 0 0 14px;
    height: auto;
    overflow: auto;
    .indLandPrice {
        margin-bottom: 0;
    }
    & > section {
        height: 100%;
    }
    & > section > div {
        padding: 24px 14px 0;
    }
    section {
        border-bottom: 6px solid ${Common.colors.grey02};
        ul {
            padding: 0 14px;
            margin-bottom: 14px;
        }
        li {
            padding: 8px 14px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            text-align: right;
            .newBox {
                display: inline-flex;
                align-items: center;
                & > .newName {
                    min-width: max-content;
                    width: max-content;
                    margin-top: 1px;
                }
            }
            span.name {
                text-align: left;
                min-width: 100px;
                width: 100px;
                font-weight: 400;
                font-size: 13px;
                line-height: 20px;
                color: ${Common.colors.darkGrey04};
            }
            span.new {
                margin-left: 4px;
                border-radius: 100px;
                background-color: #f00;
                padding: 0 4px;
                font-weight: 400;
                font-size: 12px;
                color: #fff;
                line-height: 20px;
                display: inline-block;
                padding-bottom: 1px;
            }
            span.value {
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                color: ${Common.colors.normalTextColor};
            }
            span.date {
                display: block;
                font-style: normal;
                font-weight: 400;
                font-size: 11px;
                line-height: 20px;
                color: ${Common.colors.darkGrey04};
            }
            span.blue {
                color: ${Common.colors.blue};
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
            }
        }
        ul.base li {
            border-bottom: 1px solid ${Common.colors.grey03};
            &:last-of-type {
                border-bottom: none;
            }
        }
        &:first-of-type ul li:first-of-type {
            align-items: baseline;
        }
        .buttons {
            margin-top: -20px;
            margin-bottom: 10px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            gap: 10px;
        }
        .buttonBox {
            padding: 0 14px;
            margin-bottom: 24px;
        }

        .greenButton {
            width: 100%;
            height: 56px;
            text-align: center;
            border-radius: 8px;
            background-color: ${Common.colors.solidGreen};
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            color: ${Common.colors.white};
        }
    }
`;
const tableWrap = css`
    padding: 0 !important;
    margin-bottom: 14px;
    .title.small {
        padding: 24px 14px;
    }
    table {
        width: 100%;
        thead > tr > th {
            text-align: center;

            &:nth-of-type(1) {
                width: 100px;
            }
            &:nth-of-type(2) {
                width: calc(100% - 100px);
            }
        }
        tbody > tr > td {
            text-align: center;
            div {
                width: 100%;
                height: fit-content;
                word-break: keep-all;
            }
            &:nth-of-type(1) {
                width: 100px;
            }
            &:nth-of-type(2) {
                width: calc(100% - 100px);
            }
        }
        thead tr th:first-of-type div {
            text-align: left !important;
        }
        tr td:first-of-type div {
            text-align: left !important;
        }
    }
`;

const landPriceTableWrap = (props: any) => css`
    ${props.tableWrap},
    thead tr th:last-of-type div {
        text-align: right !important;
    }
    tr td:last-of-type div {
        text-align: right !important;
    }
`;
const nullBox = css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${Common.colors.greyPrimary};
    background-color: ${Common.colors.grey02};
    border-radius: 14px;
    text-align: center;
    padding: 24px 0;
    margin: 14px;

    &.indLandPrice {
        padding-bottom: 24px !important;
        margin: 14px !important;
    }
    &.landChangeAll {
        margin-top: 0 !important;
    }
`;

const stateLandBoxStyle = (highlightCheck: boolean) => css`
    position: relative;
    margin: 0 !important;
    padding: 0px 14px 24px !important;
    & > .alignStart {
        align-items: flex-start !important;
    }
    & > .box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.6px solid #c9c9c9;
        background-color: #ffffff;
        padding: 6px 14px;
        height: 39px;
        box-sizing: border-box;
        & > .title {
            color: #8d8d8d;
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
        }
        & .desc {
            color: #0c002c;
            text-align: right;
            font-size: 16px;
            font-weight: 400;
            line-height: 26px;
        }
        & > .wrapStyle {
            white-space: pre;
            span {
                display: inline-flex;
                align-items: center;
                gap: 4px;
            }
        }
        & .status {
            ${highlightCheck && {
                background: "linear-gradient(to top,#fff500 50%,transparent 50%)",
            }}
        }
    }
    .box:last-of-type {
        border-bottom: none;
    }
    & .lockImg {
        position: absolute;
        bottom: 3px;
        right: 6px;
    }
`;

const stateLandButtonStyle = css`
    display: flex;
    flex: 1;
    width: 100%;
    margin-top: 28px;
    align-content: center;
    justify-content: center;
    height: 56px;
    text-align: center;
    column-gap: 10px;
    padding: 14px;
    border-radius: 14px;
    background-color: #0047ff;
    color: var(--basic-white, #fff);
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    box-sizing: border-box;
`;

const stateLandScrollBoxStyle = css`
    position: absolute;
    top: -130px;
    width: 100%;
    margin: 0;
`;

const stateLandTitleStyle = css`
    color: #0c002c;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    padding: 14px 28px;
    span {
        margin-left: 4px;
        color: #8d8d8d;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
    }
`;

export default LandDetail;
