import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";


export type RecentSearchKeywordItem =  {
    "no": number,
    "froms": string,
    "searchType": number,
    "keyword": string,
    "bidType": number | null,
    "itemId": string | null,
    "location": {
      "lng": number,
      "lat": number
    } | null,
    "pnu": string | null
  }

  type RecentSearchKeywordListResponse = {
    totalCount: number,
  currentPage: number,
  pageSize: number,
  nextPage: number,
  hasNext: boolean,
  lastPage: number,
    list : RecentSearchKeywordItem[],
    message: string
  }


// 최근검색어 일반 조회 (무한스크롤 적용 X) 24. 11 이전
// export const useGetRecentSearchKeywordList = () => {
//     return useQuery({
//         queryKey: ['recentSearchKeywords'],
//         queryFn: async () => {
//             const response = await axiosInstance.get<RecentSearchKeywordListResponse>(`${axiosPath.userKeywords}s`, {
//                 params: { froms: "list" },
//             });
//             return response.data;
//         },
//         select: data => data.list
//     });
// };

export const useGetRecentSearchKeywordList = (limit : number) => {
    return useInfiniteQuery({
        queryKey: ['recentSearchKeywords'],
        queryFn: async ({pageParam = 1}) => {
            const response = await axiosInstance.get<RecentSearchKeywordListResponse>(`${axiosPath.userKeywordsPagination}`, {
                params: {
                    page: pageParam,
                    limit,
                    froms: "list" 
                    },
            });
            return response.data;
        },
        getNextPageParam: (lastPage, allPages) => lastPage.hasNext ? lastPage.nextPage : undefined
    });
};

export const usePutSearchKeyword =  () => {
    const queryClient = useQueryClient()
   return useMutation({mutationFn: (keyword: string)=>axiosInstance.put(`${axiosPath.userKeywords}`, {
    keyword,
    froms: "list",
    searchType: 4, 
}), onSuccess: ()=>{
    queryClient.invalidateQueries({queryKey: ['recentSearchKeywords']})
   }})
};

export const useDeleteRecentSearchKeyword =  () => { 
    const queryClient = useQueryClient()
    return useMutation({mutationFn: (keywordId: number)=>axiosInstance.delete(`${axiosPath.userKeywords}/${keywordId}`), onSuccess: ()=>{
        queryClient.invalidateQueries({queryKey: ['recentSearchKeywords']})
       }},)
};

export const useDeleteAllRecentSearchKeywords =  (searchType: number) => {
    const queryClient = useQueryClient()
   return useMutation({mutationFn: ()=> axiosInstance.delete(`${axiosPath.userKeywords}/all/${searchType}`), onSuccess: ()=>{
    queryClient.invalidateQueries({queryKey: ['recentSearchKeywords']})
   }})
};
