import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { useMutation } from "react-query";

export const useDeungiInfo = async (id: string) => {
    const response = await axiosInstance.get(`${axiosPath.deungiInfo}/${id}`);
    return response;
};

export const useDeungiCountByPnu = async (pnu: string) => {
    const response = await axiosInstance.get(`${axiosPath.deungiCountByPnu}?pnu=${pnu}`);
    return response;
};

export const useCheckDeungi = async () => {
    const response = await axiosInstance.get(`${axiosPath.checkDeungi}`);
    return response;
};


export const useDeungiReadingUpdate = ()=>{
    return useMutation({mutationFn: (id: string)=> axiosInstance.patch(`${axiosPath.deungiReading}/${id}`)})
}

