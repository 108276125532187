import React, { useEffect, useState } from "react";

import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import { useNewFilterStore } from "@src/store/newFilterStore";

import { useSidoFilterStore } from "@src/store/filterStore";
import SearchInputAndFilter, { IFilterState, initialFilterState } from "@src/components/searchItems/SearchInputAndFilter";
import { useSearchParams } from "react-router-dom";

export const SearchItems: React.FC = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [isShowFilter, setIsShowFilter] = useState<boolean>(true);
    const filterStoreState = useNewFilterStore((state) => state.newFilterState);
    const setFilterStoreState = useNewFilterStore((state) => state.setStoreNewFilterState);
    const selectedSido = useSidoFilterStore((state) => state.selectedSido);

    WebAppBridge.changeSearchItemsFilterFromApp = (filterState) => {
        setFilterStoreState(JSON.parse(filterState) as IFilterState);
    };

    useEffect(() => {
        transferToNative("done", "onLoad");
    }, []);

    useEffect(() => {
        const type = searchParams.get("type");
        if (type === "auction") {
            setFilterStoreState({
                ...filterStoreState,
                type: 1,
            });
        } else if (type === "publicAuction") {
            setFilterStoreState({
                ...filterStoreState,
                type: 2,
            });
        } else return;
    }, [searchParams.get("type")]);

    useEffect(() => {
        const intializeFilter = () => {
            // 유저가 실제로 소재지를 선택하지 않은 경우
            if (selectedSido === "all" || null) {
                const { loc, ...rest } = initialFilterState;
                transferToNative(JSON.stringify(rest), "changeSearchItemsFilter");
            } else {
                transferToNative(JSON.stringify(initialFilterState), "changeSearchItemsFilter");
            }
        };

        const sendSavedFilter = () => {
            if (selectedSido === "all") {
                const { loc, ...rest } = filterStoreState;
                transferToNative(JSON.stringify(rest), "changeSearchItemsFilter");
            } else {
                transferToNative(JSON.stringify(filterStoreState), "changeSearchItemsFilter");
            }
        };

        isShowFilter ? sendSavedFilter() : intializeFilter();
    }, [filterStoreState, isShowFilter]);

    return (
        <StyledItems>
            <div css={FilterArea}>
                <SearchInputAndFilter isShowFilter={isShowFilter} setIsShowFilter={setIsShowFilter} />
            </div>
        </StyledItems>
    );
};

const StyledItems = styled.div`
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    position: fixed;
    overflow: hidden;
`;

const FilterArea = css`
    position: fixed;
    z-index: 9998;
    width: 100%;
`;
