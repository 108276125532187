import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import noRecentList from "../../assets/noRecentListWhite.png";
import RecentSearchKeywordItem from "@src/components/searchItems/RecentSearchKeywordItem";
import {
    useDeleteAllRecentSearchKeywords,
    useGetRecentSearchKeywordList,
} from "@src/hooks/searchItems/useRecentSearchKeywordList";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { transferToNative } from "@src/lib/WebAppBridge";

interface RecentSearchKeywordListProps {
    onClickKeyword: (keyword: string) => void;
    onClose: () => void;
}

const RecentSearchKeywordList = ({ onClickKeyword, onClose }: RecentSearchKeywordListProps) => {
    const RECENT_SEARCH_KEYWORD_LIST_LIMIT = 15;
    const SEARCH_TYPE_LIST_ITEM = 4;
    const {
        data: recentSearchKeywordsData,
        fetchNextPage,
        hasNextPage,
    } = useGetRecentSearchKeywordList(RECENT_SEARCH_KEYWORD_LIST_LIMIT);
    const { mutate: deleteAllKeywords } = useDeleteAllRecentSearchKeywords(SEARCH_TYPE_LIST_ITEM);
    const handleClose = () => {
        onClose();
    };

    const [observerRef] = useInfiniteScroll({
        loading: false,
        hasNextPage: Boolean(hasNextPage),
        onLoadMore: fetchNextPage,
        disabled: false,
        rootMargin: "0px 0px 0px 0px",
        delayInMs: 500,
    });

    useEffect(() => {
        transferToNative("full", "resizeWebview");
    }, []);

    return (
        <>
            <StyledRecentSearchList>
                <div className="titleBox">
                    <div className="title">최근검색어</div>
                    <div className="deleteClose">
                        {recentSearchKeywordsData && recentSearchKeywordsData.pages.some((page) => page.totalCount !== 0) && (
                            <div
                                className="delete"
                                onClick={() => {
                                    deleteAllKeywords();
                                }}
                            >
                                전체삭제
                            </div>
                        )}

                        <div className="close" onClick={handleClose}>
                            닫기
                        </div>
                    </div>
                </div>
                <div className="list">
                    {recentSearchKeywordsData?.pages.map((page) => (
                        <div key={page.currentPage}>
                            {page.list.length === 0 && (
                                <StyledNoRecentSearchList>
                                    <div className="content">
                                        <img className="noListImg" src={noRecentList} alt="" />
                                        <div className="noListText">최근 검색 기록이 없어요</div>
                                    </div>
                                </StyledNoRecentSearchList>
                            )}
                            {page.list.length !== 0 &&
                                page.list.map((item) => (
                                    <RecentSearchKeywordItem
                                        text={item.keyword}
                                        key={item.no}
                                        no={item.no}
                                        onClickKeyword={onClickKeyword}
                                    />
                                ))}
                            <div className="observed" ref={observerRef} />
                        </div>
                    ))}
                </div>
            </StyledRecentSearchList>
        </>
    );
};

export default RecentSearchKeywordList;

const StyledRecentSearchList = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 9998;
    top: 58px;
    left: 0;
    background-color: rgba(40, 40, 40, 0.8);
    padding-top: 14px;
    .titleBox {
        display: flex;
        width: 100%;
        justify-content: space-between;
        padding: 0px 14px;
        height: 26px;
        .title {
            font-weight: 700;
            font-size: 16px;
            color: white;
            display: flex;
            align-items: center;
        }
        .deleteClose {
            font-weight: 400;
            font-size: 13px;
            color: white;
            display: flex;
            align-items: flex-end !important;
            gap: 10px;
        }
    }
    .list {
        padding-top: 14px;
        height: calc(100vh - 98px);
        padding-bottom: 20px;
        overflow-y: scroll;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
        .observed {
            height: 1px;
            width: 100%;
        }
    }
`;

const StyledNoRecentSearchList = styled.div`
    width: 100%;
    height: calc(calc(var(--vh, 1vh) * 100) - 88px);
    display: flex;

    justify-content: center;
    .content {
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 23px;
        .noListImg {
            width: 50px;
            height: 60px;
        }
        .noListText {
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
        }
    }
`;
