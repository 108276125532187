import { css } from "@emotion/react";
import React, { useEffect, useState } from "react";
import ico_price_down from "@src/assets/ico_price_down_bold.svg";
import ico_price_up from "@src/assets/ico_price_up.svg";
import ico_like_red from "@src/assets/ico_like_red.png";
import ico_like_grey from "@src/assets/ico_like_grey.png";
import noImg from "@src/assets/itemNoImg.png";
import { GetItemListType } from "@src/hooks/item/useGetItem";
import { transferStringAuctionType } from "@src/util/typeToName";
import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import { useSaverFavoriteItem } from "@src/hooks/item/useFavoriteIte";
import ConfirmModal from "../popup/ConfirmModal";

type ItemPropsTypes = GetItemListType & {
    onClick: () => void;
};

const Item = ({
    bid_type,
    use,
    case_number,
    is_favorited,
    skp_cnt_str,
    image,
    address,
    est_price_str,
    est_price,
    min_price_str,
    min_price,
    rate,
    d_day,
    due_date_str,
    somethings,
    status,
    status_detail,
    sale_price_str,
    sale_price_rate,
    card_type,
    date_info,
    onClick,
    item_id,
}: ItemPropsTypes) => {
    function escapeRegex(string: string) {
        return string.replace(/[(), -]/g, ``);
    }

    const isPriceUp = (price?: string) => {
        return price?.includes("+");
    };

    const [viewRate, setViewRate] = useState("");
    const [change, setChange] = useState("");

    const [favoriteToast, setFavoriteToast] = useState(0);

    useEffect(() => {
        if (favoriteToast === 1 || favoriteToast === 2) {
            const timer = setTimeout(() => {
                setFavoriteToast(0);
            }, 2000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [favoriteToast]);

    const [favoriteCheck, setFavoriteCheck] = useState<boolean>(is_favorited);
    const [memoModal, setMemoModal] = useState(0);

    useEffect(() => {
        if (rate === "(0%)") {
            setViewRate("0%");
            setChange("normal");
        }
        if (rate.includes("-")) {
            const rate2 = rate.replace("(", "");
            const result = rate2.replace(")", "");
            const setResult = result.replace("-", "");
            setViewRate(setResult);
            setChange("degradation");
        }
        if (rate.includes("+")) {
            const rate2 = rate.replace("(", "");
            const result = rate2.replace(")", "");
            const setResult = result.replace("+", "");
            setViewRate(setResult);
            setChange("increase");
        }
    }, [rate]);

    const handleImgError = (e: any) => {
        e.target.src = noImg;
    };

    const updateFavoriteItem = useSaverFavoriteItem(
        (res, _variables) => {
            if (res?.data?.payload?.result?.deletedCount === 0) {
                setFavoriteToast(1);
            } else if (res?.data?.payload?.result?.deletedCount === 1) {
                setFavoriteToast(2);
            }
            setFavoriteCheck(res?.data?.payload?.result?.deletedCount === 0 ? true : false);
        },
        () => {
            console.log("err>");
        },
    );

    return (
        <div css={Container} onClick={onClick}>
            <ItemCard status={status} statusDetail={status_detail} cardType={card_type}>
                <div className="item_nav">
                    <div>
                        <span css={bidTypeStr(transferStringAuctionType(bid_type))}>{transferStringAuctionType(bid_type)}</span>
                        <b>{case_number}</b>
                    </div>
                    <div className="right">
                        {bid_type === 1 && (
                            <>
                                {card_type === 0 && (
                                    <>
                                        <span className="format_date">{date_info}</span>
                                        <span
                                            className="title_date"
                                            style={{
                                                marginLeft: "8px",
                                            }}
                                        >
                                            {d_day}
                                        </span>
                                    </>
                                )}
                                {card_type === 1 && (
                                    <span
                                        className="format_date"
                                        style={{
                                            marginRight: "8px",
                                            color: "#0047FF",
                                        }}
                                    >
                                        {status_detail}
                                    </span>
                                )}
                                {card_type === 2 &&
                                    (date_info === "-" ? (
                                        <span className="format_date">매각</span>
                                    ) : (
                                        <span className="format_date">
                                            <span className="format_date_bold">{date_info.split(" ")[0]} </span>
                                            {date_info.split(" ")[1]}
                                        </span>
                                    ))}
                            </>
                        )}
                        {/* 공매 */}
                        {bid_type === 2 && (
                            <>
                                {card_type === 0 && (
                                    <>
                                        <span
                                            className="title_date"
                                            style={{
                                                marginLeft: "8px",
                                            }}
                                        >
                                            {d_day}
                                        </span>
                                    </>
                                )}
                                {card_type === 1 && (
                                    <span
                                        className="format_date"
                                        style={{
                                            marginRight: "8px",
                                            color: "#0047FF",
                                        }}
                                    >
                                        {status_detail}
                                    </span>
                                )}
                                {card_type === 2 && (
                                    <>
                                        <span
                                            className="format_date"
                                            style={{
                                                marginLeft: "8px",
                                            }}
                                        >
                                            {date_info}
                                        </span>
                                    </>
                                )}
                            </>
                        )}
                        {favoriteCheck ? (
                            <LikeImg
                                onClick={(e) => {
                                    setFavoriteToast(0);
                                    e.stopPropagation();
                                    updateFavoriteItem.mutate(item_id);
                                }}
                                src={ico_like_red}
                                alt="favorite"
                            />
                        ) : (
                            <LikeImg
                                onClick={(e) => {
                                    setFavoriteToast(0);
                                    e.stopPropagation();
                                    updateFavoriteItem.mutate(item_id);
                                }}
                                src={ico_like_grey}
                                alt="least favorite"
                            />
                        )}
                    </div>
                </div>
                <div css={ItemWrapper}>
                    <div css={ImageArea}>
                        <img src={image ?? noImg} onError={handleImgError} />
                        <Tag skp_cnt_str={skp_cnt_str}>
                            <span>{skp_cnt_str}</span>
                        </Tag>
                    </div>
                    <InfoArea type={skp_cnt_str} status={status}>
                        <div className="title">
                            <h3>{use}</h3>
                        </div>
                        <div className="item_address">{address}</div>
                        <span className="price">감정가 {est_price === -1 ? "-" : est_price_str}</span>
                        {status === "매각" && <span className="price">최저가 {min_price === -1 ? "-" : min_price_str}</span>}
                        <div className="row-price-wrapper">
                            {status === "매각" ? (
                                <div className="sale_price">
                                    <span>
                                        매각가{" "}
                                        {sale_price_str === "비공개" || sale_price_str === "0원" ? (
                                            <b style={{ color: "#0C002C" }}> - </b>
                                        ) : (
                                            <>
                                                <b style={{ color: "#0C002C" }}>{sale_price_str} </b>
                                                <b style={{ color: "#FF0000", fontSize: "13px" }}>
                                                    {sale_price_rate.replace("-", "")}
                                                </b>
                                            </>
                                        )}
                                    </span>
                                </div>
                            ) : (
                                <span>
                                    최저가{" "}
                                    <b>
                                        {min_price_str === "비공개" ? "-" : min_price_str}{" "}
                                        {rate !== "(0%)" ? (
                                            <span className="price_percent">
                                                {/* <img src={rate && isPriceUp(rate) ? ico_price_up : ico_price_down} /> {viewRate} */}
                                                {change === "normal" && (
                                                    <>
                                                        <span style={{ color: "black", fontSize: "13px" }}>{viewRate}</span>
                                                    </>
                                                )}
                                                {change === "degradation" && (
                                                    <>
                                                        <img src={ico_price_down} alt="상승률 하락률" />
                                                        <span style={{ color: `${Common.colors.blue}` }}>{viewRate}</span>
                                                    </>
                                                )}
                                                {change === "increase" && (
                                                    <>
                                                        <img src={ico_price_up} alt="상승률 하락률" />
                                                        <span style={{ color: `${Common.colors.notiColor}` }}>{viewRate}</span>
                                                    </>
                                                )}
                                            </span>
                                        ) : null}
                                    </b>
                                </span>
                            )}
                            {status !== "매각" && (
                                <div className="item_footer">
                                    <div className="tags">
                                        {somethings.map((item, key) => (
                                            <div
                                                key={key}
                                                css={
                                                    item.startsWith("인수") && item.endsWith("예상") ? specialTagStyle : tagStyle
                                                }
                                            >
                                                {item.startsWith("인수") && item.endsWith("예상") ? (
                                                    <>
                                                        <span css={insuStyle}>인수</span>
                                                        <span css={yesangStyle}>{item.slice(2)}</span>
                                                    </>
                                                ) : (
                                                    item
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </InfoArea>
                </div>
            </ItemCard>
            {memoModal === 1 && (
                <ConfirmModal
                    onConfirm={() => {
                        console.log("asd");
                    }}
                    resultMessage={
                        <div style={{ lineHeight: "26px" }}>
                            {"관심매물로 등록되었어요!"}
                            <p style={{ fontSize: "12px", color: "#0c002c", lineHeight: "20px" }}>
                                관심매물은 메모를 등록할 수 있고 <br />
                                매각결과를 푸시로 전송해드려요 !
                            </p>
                        </div>
                    }
                    buttonText="확인"
                />
            )}
            {favoriteToast === 1 && <div css={favoriteToastStyle}>관심 매물로 등록되었습니다</div>}
            {favoriteToast === 2 && <div css={favoriteToastStyle}>관심 매물에서 삭제되었습니다</div>}
        </div>
    );
};

export default Item;

const favoriteToastStyle = css`
    position: absolute;
    z-index: 9999;
    width: calc(100% - 28px);
    left: 14px;
    padding: 8px 14px;
    font-size: 14px;
    line-height: 24px;
    background: #282828b2;
    border-radius: 14px;
    color: white;
    bottom: 26px;
    display: flex;
    justify-content: center;
`;

const ItemWrapper = css`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    border-bottom: 0.6px solid #dadada;
    .date {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .tags::-webkit-scrollbar {
        display: none;
    }
    .item_footer {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: flex-start;
        img {
            max-width: 20px;
            max-height: 18px;
            flex-wrap: wrap;
        }
    }
    .tags {
        display: flex;
        width: 100%;
        flex: 1;
        overflow-x: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        white-space: nowrap;
        overflow: hidden;
        flex-wrap: wrap;
        width: 100%;
        text-overflow: ellipsis;
        gap: 4px;
        img {
            float: right;
        }
    }
`;

const specialTagStyle = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;

const baseTagStyle = css`
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    font-style: normal;
    white-space: nowrap;
`;

const tagStyle = css`
    ${baseTagStyle}

    padding: 0px 8px;
    color: #ffffff;
    background-color: #ff0000;
    border-radius: 100px;
    clear: both;
`;

const insuStyle = css`
    ${baseTagStyle}

    border-radius: 100px;
    color: #00ff00;
    background-color: #000;
    padding: 0px 5px;
`;

const yesangStyle = css`
    ${baseTagStyle}

    color: #ff0000;
`;

const ItemCard = styled.div<{ status: string; statusDetail: string; cardType: number }>`
    padding: 0px 16px 0px 16px;
    ${(props) =>
        props.cardType === 2 &&
        css`
            background-color: #fff5f5;
        `}
    ${(props) =>
        props.cardType === 1 &&
        css`
            background-color: #f0f0f0;
        `} /* ${(props) =>
        props.statusDetail === "변경" ||
        props.statusDetail === "취하" ||
        props.statusDetail === "기각" ||
        props.statusDetail === "취소"
            ? css`
                  background-color: #f0f0f0;
              `
            : null} */
`;

const LikeImg = styled.img`
    max-width: 20px;
    max-height: 18px;
`;

const Container = css`
    box-sizing: border-box;
    .item_address {
        font-size: 13px;
        font-weight: 400;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        /* width: 150px; */
    }
    .item_nav {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        padding-top: 14px;
        span {
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
        }
        b {
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: ${Common.colors.darkGrey03};
            margin-left: 4px;
        }

        .right {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }
    .title_date {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: #ff0000;
        margin-left: 8px;
    }
    .format_date {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0px;
        color: #505050;
        .format_date_bold {
            font-weight: 700;
        }
    }
`;

const ImageArea = css`
    position: relative;
    display: flex;
    align-items: center;
    margin-right: 12px;
    img {
        object-fit: fill;
        width: 108px;
        height: 108px;
        border-radius: 8px;
    }
`;

const InfoArea = styled.div<{ type: string; status: string }>`
    flex: 1;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    margin-top: 10px;
    padding-bottom: 14px;
    h3 {
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
    }
    .title {
        display: flex;
        flex: 1;
        justify-content: space-between;
        align-items: flex-end;
        span {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            color: #34b531;
            color: ${({ type }) =>
                type.includes("신건") ? `${Common.colors.purpleSecondary}` : `${Common.colors.redSecondary}`};
        }
    }
    .price {
        display: block;
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
    }
    .row-price-wrapper {
        & > span {
            font-size: 14px;
            font-weight: 700;
            line-height: 24px;
            color: ${Common.colors.blue};
            ${({ status }) => {
                if (status === "매각")
                    return css`
                        color: #ff0000;
                    `;
            }}
            & > b {
                font-size: 15px;
                font-weight: 700;
                line-height: 26px;
                color: ${Common.colors.black};
            }
            & > img {
                min-width: 20px;
                min-height: 18px;
            }
        }
        .sale_price {
            display: flex;
            justify-content: space-between;
            flex-direction: row;
            margin-top: 4px;
            margin-bottom: 16px;
            ${({ status }) => {
                if (status === "매각")
                    return css`
                        color: #ff0000;
                    `;
            }}
            & > b {
                font-size: 15px;
                font-weight: 700;
                line-height: 26px;
                color: ${Common.colors.black};
            }
            & > span {
                font-weight: 700;
            }
        }
        .price_percent {
            display: inline-flex;
            align-items: center;
            font-size: 13px;
            line-height: 20px;
            img {
                width: 10px;
                margin: 0px 4px 0px 2px;
            }
            & > span {
                display: inline-flex;
                align-items: center;
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
`;

const Tag = styled.div<{ skp_cnt_str: string }>`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 4px;
    top: 4px;
    height: 20px;
    min-width: 32px;
    border-radius: 4px;
    padding: 2px 6px 2px 6px;
    box-sizing: border-box;
    ${({ skp_cnt_str }) => {
        if (skp_cnt_str === "신건")
            return css`
                background: ${Common.colors.purpleSecondary};
                color: ${Common.colors.white};
            `;
        else
            return css`
                background: ${Common.colors.yellowSecondary};
                color: ${Common.colors.normalTextColor};
            `;
    }}
    span {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        white-space: nowrap;
        letter-spacing: 0px;
        padding-top: 3px;
    }
`;

const bidTypeStr = (type: string) => css`
    color: white;
    background-color: ${type === "경매" ? "#2275EE" : type === "공매" ? "#F1BB40" : ""};
    padding: 5px 8px;
    border-radius: 4px;
`;
