import { initialFilterParams } from "@src/pages/newFilter";
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import AuctionTypeFilter from "@src/pages/newFilter/AuctionTypeFilter";
import NewLocationFilter from "@src/pages/newFilter/NewLocationFilter";
import LandUseFilter from "@src/pages/newFilter/LandUseFilter";
import AuctionMasterItemFilter from "@src/pages/newFilter/AuctionMasterItemFilter";
import AuctionStatusFilter from "@src/pages/newFilter/AuctionStatusFilter";
import AuctionSkipFilter from "@src/pages/newFilter/AuctionSkipFilter";
import DueDateFilter from "@src/pages/newFilter/DueDateFilter";
import EstPriceFilter from "@src/pages/newFilter/EstPriceFilter";
import MinPriceFilter from "@src/pages/newFilter/MinPriceFilter";
import FilterHeader from "@src/components/filter/Header";
import ToastMessage from "@src/components/filter/ToastMessage";
import AiResetConfirmModal from "../aiFilter/AiResetConfirmModal";
import { useNewFilterMapCheckStore, useNewFilterStore } from "@src/store/newFilterStore";
import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";
import { Common } from "@src/styles/Common";
import SelectedFilterList from "./SelectedFilterList";
import AuctionCounterForceFilter from "./AuctionCounterForceFilter";
import { useFilterStore } from "@src/store/filterStore";

interface Props {}

const NewMainFilter: React.FC<Props> = () => {
    const navigate = useNavigate();
    const filterParams = useNewFilterStore((state) => state.newFilterState);
    const setFilterParams = useNewFilterStore((state) => state.setStoreNewFilterState);
    const setFilterCheck = useNewFilterMapCheckStore((state) => state.setNewFilterMapCheckState);
    const [toastVisible, setToastVisible] = useState<boolean>(false);
    const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);
    const selectedOutSido = useFilterStore((state) => state.selectedOutSido);
    const setSelectedOutSido = useFilterStore((state) => state.setSelectedOutSido);

    const onClickDone = () => {
        setFilterCheck(false);
        transferToNative(JSON.stringify(filterParams), "setFilterDone");
        navigate(-1);
    };

    WebAppBridge.filterDone = () => {
        setFilterCheck(false);
        transferToNative(JSON.stringify(filterParams), "setFilterDone");
        navigate(-1);
    };

    return (
        <div css={rootBoxStyle}>
            <FilterHeader>검색 설정</FilterHeader>
            <div css={{ height: "60px" }} />
            <div css={contentWrap}>
                <AuctionTypeFilter filterParams={filterParams} setFilterParams={setFilterParams} />
                <NewLocationFilter
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    showToast={() => setToastVisible(true)}
                    outSelectedSido={selectedOutSido}
                    setOutSelectedSido={(val: string) => setSelectedOutSido !== undefined && setSelectedOutSido(val)}
                />
                <LandUseFilter
                    filterParams={filterParams}
                    setFilterParams={setFilterParams}
                    showToast={() => setToastVisible(true)}
                />
                <div css={lineStyle} />
                <AuctionCounterForceFilter filterParams={filterParams} setFilterParams={setFilterParams} />
                <div css={lineStyle} />
                <AuctionMasterItemFilter filterParams={filterParams} setFilterParams={setFilterParams} />
                <div css={lineStyle} />
                <AuctionStatusFilter filterParams={filterParams} setFilterParams={setFilterParams} />
                <div css={lineStyle} />
                <AuctionSkipFilter filterParams={filterParams} setFilterParams={setFilterParams} />
                <div css={lineStyle} />
                <DueDateFilter filterParams={filterParams} setFilterParams={setFilterParams} />
                <div css={lineStyle} />
                <EstPriceFilter filterParams={filterParams} setFilterParams={setFilterParams} />
                <div css={lineStyle} />
                <MinPriceFilter filterParams={filterParams} setFilterParams={setFilterParams} />

                <div css={{ height: "200px" }} />
                <div css={footerRootStyle}>
                    <SelectedFilterList filterParams={filterParams} />
                    <div css={footerButtonBoxStyle}>
                        <button onClick={() => setResetModalVisible(true)}>
                            <span>초기화</span>
                        </button>
                        <button className="active" onClick={onClickDone}>
                            <span>적용하기</span>
                        </button>
                    </div>
                </div>
            </div>
            <ToastMessage visible={toastVisible} setToastVisible={() => setToastVisible(false)}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
            {resetModalVisible && (
                <AiResetConfirmModal
                    text="매물필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={() => {
                        setFilterParams(initialFilterParams);
                        setResetModalVisible(false);
                        setSelectedOutSido("all");
                    }}
                />
            )}
        </div>
    );
};

export default NewMainFilter;

const rootBoxStyle = css`
    background-color: #ffffff;
    height: calc(var(--vh, 1vh) * 100);
    height: 100dvh;
    height: 100vh;
    /* -ms-overflow-style: none;
    scrollbar-width: none; */
`;

const contentWrap = css`
    height: calc(100vh - 60px);
    /* height: calc(100vh - 250px); */
    overflow-y: auto;
    &::-webkit-scrollbar {
        border-radius: 4px;
        width: 6px;
        height: 4px;
        margin-right: 10px;
        margin-top: 20px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        background-color: #c9c9c9;
    }
    &::-webkit-scrollbar-track {
        border-radius: 20px;
        background-color: #f0f0f0;
    }
`;

const footerButtonBoxStyle = css`
    display: flex;
    height: 94px;
    padding: 14px 14px 24px 14px;
    button {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 56px;
        border-radius: 14px;
        padding: 14px;
        background: #c3c3c3;
        span {
            font-size: 18px;
            color: #ffffff;
            line-height: 28px;
        }
    }
    button.active {
        background: ${Common.colors.aucMarkerColor};
    }
    button + button {
        margin-left: 12px;
    }
`;

const footerRootStyle = css`
    position: fixed;
    bottom: 0px;
    width: 100%;
    z-index: 999;
    background-color: #ffffff;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

const lineStyle = css`
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
`;
