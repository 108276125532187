import React from "react";
import styled from "@emotion/styled";
import { Common } from "@src/styles/Common";
import textImg from "../../assets/searchTextIcon.png";
import ClearIcon from "@mui/icons-material/Clear";
import { useDeleteRecentSearchKeyword } from "@src/hooks/searchItems/useRecentSearchKeywordList";

interface RecentSearchKeywordItemProps {
    no: number;
    text: string;
    onClickKeyword: (SearchKeyword: string) => void;
}

const RecentSearchKeywordItem = ({ no, text, onClickKeyword }: RecentSearchKeywordItemProps) => {
    const { mutate: deleteSearchKeyword } = useDeleteRecentSearchKeyword();
    return (
        <>
            <StyledRecentSearchItem>
                <div
                    className="clickDiv"
                    onClick={() => {
                        onClickKeyword(text);
                    }}
                >
                    <img src={textImg} alt="" className="typeMarker" />
                    <div className="recentTextDiv">
                        <div className="recentSearchText">{text}</div>
                    </div>
                </div>
                <ClearIcon
                    className="deleteBtn"
                    onClick={() => {
                        deleteSearchKeyword(no);
                    }}
                />
            </StyledRecentSearchItem>
        </>
    );
};

export default RecentSearchKeywordItem;

const StyledRecentSearchItem = styled.div`
    display: flex;
    gap: 11px;
    min-height: 34px;
    height: fit-content;
    width: 100%;
    padding-top: 10px;
    position: relative;
    padding: 10px 17px 0 17px;

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    :active {
        width: 100vw;
        .recentTextDiv {
            .recentSearchText {
                color: ${Common.colors.white};
            }
        }
        .deleteBtn {
            color: ${Common.colors.white};
        }
    }
    .clickDiv {
        display: flex;
        width: calc(100% - 20px);
        gap: 11px;
        .typeMarker {
            width: 24px;
            height: 24px;
            display: flex;
            align-items: flex-start;
        }

        .recentTextDiv {
            display: flex;
            width: 100%;
            border-bottom: 1px solid ${Common.colors.grey03};
            padding-bottom: 10px;
            align-items: flex-start;
            min-height: 34px;
            height: fit-content;
            .recentSearchText {
                font-weight: 400;
                font-size: 14px;
                width: 100%;
                height: fit-content;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                color: white;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-all;
            }
        }
    }

    .deleteBtn {
        color: white;
        position: absolute;
        top: 14px;
        right: 17px;
        font-size: 18px;
    }
`;
