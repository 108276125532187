import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { DeungiAlarmSubmitRequestDto, UserAgreementsRequestDto } from "./dto/DeungiAlarm.dto";

export const getDeungiReading = async () => {
    const response = await axiosInstance.get(`${axiosPath.deungiReading}`);
    return response;
};

export const useDeungiMaskingOwner = async (pin: string) => {
    const response = await axiosInstance.get(`${axiosPath.deungiOwner}/${pin}`);
    return response;
};

export const useDeungiChangeAlarmList = async () => {
    const response = await axiosInstance.get(`${axiosPath.deungiChangeAlarm}/items`);
    return response;
};

export const useDeungiChangeAlarmSubmit = async (params: DeungiAlarmSubmitRequestDto) => {
    const response = await axiosInstance.post(`${axiosPath.deungiChangeAlarm}`, params);
    return response;
};

export const useDeungiChangeAlarmModify = async (id: string, owner: string) => {
    const response = await axiosInstance.patch(`${axiosPath.deungiChangeAlarm}/${id}`, { owner });
    return response;
};

export const useDeungiChangeAlarmExpired = async (id: string) => {
    const response = await axiosInstance.post(`${axiosPath.deungiChangeAlarm}/${id}/expired`);
    return response;
};

export const useDeungiChangeAlarmAgreement = async () => {
    const response = await axiosInstance.get(`${axiosPath.agreements}`);
    return response;
};

export const useDeungiChangeAlarmSubmitAgreement = async (params: UserAgreementsRequestDto) => {
    const response = await axiosInstance.put(`${axiosPath.agreements}`, {
        userAgreements: [
            {
                type: "agree_push_deungi_change_alarm",
                agreement: params.agree_push_deungi_change_alarm,
            },
            {
                type: "agree_email_deungi_change_alarm",
                agreement: params.agree_email_deungi_change_alarm,
            },
        ],
    });
    return response;
};
