import { css } from "@emotion/react";
import React, { useEffect } from "react";
import FieldWrap from "@src/pages/map/FieldWrap";

interface CardDataSheetProps {
    data?: any;
    content?: any;
    checked: boolean;
    type: "land" | "building";
    location: naver.maps.LatLng;
    cardData: {
        land: any;
        building: any;
        pnu: string;
    };
    handleSwipDown: () => void;
    buildingCardProps: any;
    // !임시로 빌딩 평형 정보 넣기(다른 사람 일정상 임의 작업 추후 수정 필요
    buildingSize?: any;
    from?: string;
    fieldJimok: string;
    address: string;
    areaSize?: { pyong: string; square: string };
    eumMapLinks: any;
}

function CardDataSheet({
    data,
    content,
    checked,
    type,
    location,
    cardData,
    handleSwipDown,
    buildingCardProps,
    buildingSize,
    from,
    fieldJimok,
    address,
    areaSize,
    eumMapLinks,
}: CardDataSheetProps) {
    return (
        <>
            <div css={Wrapper1} ref={content}>
                <FieldWrap
                    data={data}
                    checked={checked}
                    type={type}
                    location={location}
                    cardData={cardData}
                    handleSwipDown={handleSwipDown}
                    buildingCardProps={buildingCardProps}
                    buildingSize={buildingSize}
                    from={from}
                    fieldJimok={fieldJimok}
                    address={address}
                    areaSize={areaSize}
                    eumMapLinks={eumMapLinks}
                />
            </div>
        </>
    );
}

const Wrapper1 = css`
    width: 100%;
    height: 100vh;
    position: absolute;
    z-index: -1;
    opacity: 1;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    overflow: scroll;
    /* -webkit-overflow-scrolling: touch; */
`;

export default CardDataSheet;
