import React, { useEffect, useMemo, useRef, useState } from "react";
import { css } from "@emotion/react";
import { Common } from "@src/styles/Common";
import backIcon from "@src/assets/ico_arrow_back.png";
import shareIcon from "@src/assets/ico_share.svg";
import LandDetail from "./LandDetail";
import BuildingDetail from "./BuildingDetail";
import { initRealPriceDetailData, useGetRealPriceSummary, realPriceType } from "@src/hooks/map/useGetRealPriceDetail";
import { initLandDetailData, useGetLandDetail, initLandDetailDataDto } from "@src/hooks/map/useGetLandDetail";
import { initTypeList, useGetBuildingDetail } from "@src/hooks/map/useGetBuildingDetail";
import RealPriceDetail from "./RealPriceDetail";
import Title from "@src/components/title/Title";
import styled from "@emotion/styled";
import AD_IMAGE from "@src/assets/image_ad_map.png";
import { transferToNative } from "@src/lib/WebAppBridge";
import { isShowLabel } from "@src/util/isShowBottomSheetLabel";
import BuildingCard from "./BuildingCard";
import { useDeungiCountByPnu } from "@src/hooks/deungi/useDeungiInfo";
import { useNavigate, useSearchParams } from "react-router-dom";
import DeungiPopup from "@src/components/deungi/DeungiPopup";
import { DEUNGI_POPUP_TYPE } from "@src/components/deungi/common/enum";
import { useOpenStateLandFilterStore } from "@src/store/stateLandFilter";
import { useUpdateFieldDetailCount, useUserInfo } from "@src/hooks/user/userApi";
import DeungiOpenButton from "../deungi/DeungiButton";
import { mq } from "../../util/media";

interface FieldDetailProps {
    checked: boolean;
    location: naver.maps.LatLng;
    type: "land" | "building";
    cardData: {
        land: any;
        building: any;
        pnu: string;
    };
    handleSwipDown: () => void;
    data: any;
    buildingCardProps: any;
    buildingSize?: any;
    from?: string;
    fieldJimok: string;
    address: string;
    areaSize?: { pyong: string; square: string };
    eumMapLinks: any;
}

function FieldWrap({
    checked,
    location,
    type = "land",
    cardData,
    data,
    handleSwipDown,
    buildingCardProps,
    buildingSize,
    from,
    fieldJimok,
    address,
    areaSize,
    eumMapLinks,
}: FieldDetailProps) {
    const TAB_TITLES = ["실거래", "토지", "건물", "등기"];

    const navRef = useRef<any[]>([]);
    const tabRef = useRef<any[]>([]);
    const tabScrollRef = useRef<any[]>([]);
    const shareRef = useRef<HTMLButtonElement | null>(null);
    const shareImgRef = useRef<HTMLImageElement | null>(null);
    const realPriceRef = useRef<HTMLDivElement | null>(null);
    const landInfoRef = useRef<HTMLUListElement | null>(null);
    const buildingRef = useRef<HTMLDivElement | null>(null);
    const deungiRef = useRef<HTMLParagraphElement | null>(null);
    const stateLandInfoRef = useRef<HTMLDivElement | null>(null);
    const checkScrollRef = useRef<any>(false);
    const currentTabRef = useRef<any>(tabRef.current[0]);
    const [currentTab, setCurrentTab] = useState<any>(tabRef.current[0]);
    const [realPriceDetailData, setRealPriceDetailData] = useState<realPriceType>(initRealPriceDetailData); //실거래가 데이터
    const [landDetailData, setLandDetailData] = useState<initLandDetailDataDto>(initLandDetailData);
    const [buildingDetailData, setBuildingDetailData] = useState<any>({
        data: {
            total: [{ base: {}, building: [], parkingLot: {}, puri: {}, dates: {} }],
            single: [{ base: {}, building: [], parkingLot: {}, puri: {}, dates: {}, elevator: {}, jeonyu: null }],
        },
        typeList: initTypeList,
    });
    const [isApiCall, setIsApiCall] = useState(false);
    const [isLoading, setIsLoading] = useState(from !== "app");
    const [cardType, setCardType] = useState<"land" | "building">("land");

    const startStateLand = useOpenStateLandFilterStore((state) => state.startStateLand);

    const onClickShare = () => {
        const showAddressAndBuildingName = `${
            cardData.building.roadAddr !== "일치하는 도로명 주소가 없습니다" ? cardData.building.roadAddr : cardData.land.address
        } ${cardData.building.buildingNm !== "-" ? cardData.building.buildingNm : ""}`;
        const newParam = {
            lat: cardData.land.position.y.toString(),
            lng: cardData.land.position.x.toString(),
            pnu: cardData.pnu,
            address: showAddressAndBuildingName ?? "",
        };
        transferToNative(JSON.stringify(newParam), "fieldShare");
    };

    const [itemType, setItemType] = useState<"집합" | "일반" | "토지">("토지");
    const [popupType, setPopupType] = useState<DEUNGI_POPUP_TYPE>(DEUNGI_POPUP_TYPE.NO_DEUNGI);
    const [popupToggle, setPopupToggle] = useState(false);
    const [puriInfo, setPuriInfo] = useState<any>();
    const navigate = useNavigate();
    useEffect(() => {
        if (checked) {
            setTimeout(() => {
                setIsApiCall(true);
            }, 200);
        }
    }, [checked]);
    useEffect(() => {
        //스티키 탭 인터랙션
        let tabObserver: any;
        if (isApiCall) {
            tabRef.current = [realPriceRef.current, landInfoRef.current, buildingRef.current, deungiRef.current];
            if (tabRef.current) {
                currentTabRef.current = tabRef?.current[0];
                setCurrentTab(tabRef?.current[0]);
            }
            const changeTab = (entries: any[]) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting && !checkScrollRef.current) {
                        currentTabRef.current = entry.target;
                        setCurrentTab(entry.target);
                    }
                });
            };
            const observerOption = { rootMargin: "10% 0px", threshold: 1 };
            tabObserver = new IntersectionObserver(changeTab, observerOption);
            tabRef.current.forEach((tab: any) => tabObserver.observe(tab));
        }
        return () => {
            !isApiCall && tabObserver && tabObserver.disconnect();
        };
    }, [isApiCall]);

    const isInvalidString = (str: string) => {
        if (str === " ") return "";
        return str;
    };
    let RealPriceApiParams = useRef<any>();
    const isBuildingDataNone = ({ total, single }: any) => {
        if (!total || !single) return true;

        let isTotalNone = total.length === 0;
        let isSingleNone = single.length === 0;
        return isTotalNone && isSingleNone;
    };
    const [isBuildingNone, setIsBuildingNone] = useState(false);
    useEffect(() => {
        //데이터 정제 및 api 호출
        from !== "app" ? setIsLoading(true) : setIsLoading(false);
        if (isApiCall) {
            setIsLoading(false);

            const { land, building, pnu } = cardData;
            if (land?.origin && building?.origin) {
                // //* 먼저 값저장
                setLandDetailData({
                    base: land?.base,
                    landUsePlan: {
                        etcLaw: {
                            conflict: "-",
                            connect: "-",
                            inclusion: "-",
                        },
                        landLaw: {
                            conflict: "-",
                            connect: "-",
                            inclusion: "-",
                        },
                        table: [],
                    },
                    officialPrice: [],
                });
                setBuildingDetailData({
                    data: building?.data,
                    typeList: building?.typeList,
                    totalBuildingTable: building.totalBuildingTable,
                });
                setPuriInfo(building?.data?.total?.[0]?.puri ?? building?.data?.single?.[0]?.puri);
                const landOrigin = land?.origin;
                const buildingOrigin = building?.origin;
                const isNone = isBuildingDataNone({ total: buildingOrigin?.total, single: buildingOrigin?.single });
                setIsBuildingNone(isNone);
                const getPriceParams = {
                    pnu,
                    latd: location.lat(),
                    lntd: location.lng(),
                    land_category: isInvalidString(landOrigin?.res1?.[0]?.lndcgrCodeNm ?? landOrigin?.res1?.lndcgrCodeNm ?? ""),
                    land_use_district: isInvalidString(
                        landOrigin?.res1?.[0]?.prposArea1Nm ?? landOrigin?.res1?.prposArea1Nm ?? "",
                    ),
                    bld_main_use: isInvalidString(
                        buildingOrigin?.total?.mainPurpsCdNm ?? buildingOrigin?.total?.[0]?.mainPurpsCdNm ?? "",
                    ),
                    // area: isInvalidString(landOrigin?.lndpclAr ?? ""),
                    // !임시로 빌딩 평형 정보 넣기(다른 사람 일정상 임의 작업 추후 수정 필요
                    area:
                        cardType === "land"
                            ? landOrigin?.res1?.[0]?.lndpclAr?.pyong?.slice(0, -1) ??
                              landOrigin?.res1?.lndpclAr?.pyong?.slice(0, -1) ??
                              undefined
                            : Number(buildingSize)
                            ? buildingSize
                            : undefined,
                    sort: 2,
                };
                setIsLoading(false);

                const getPrice = useGetRealPriceSummary(getPriceParams);
                getPrice.then((res: any) => setRealPriceDetailData(res));
                RealPriceApiParams.current = getPriceParams;

                const getLand = useGetLandDetail({ origin: landOrigin, pnu });
                getLand
                    .then((res: any) => {
                        setLandDetailData({ ...res, base: { ...res.base, altitude: land?.base?.altitude } });
                    })
                    .catch(() => {
                        setLandDetailData({ base: land?.base, landUsePlan: {}, officialPrice: [] });
                    });
                if (searchParams.get("banner")) {
                    setTimeout(() => {
                        setCurrentTab(tabRef.current[1]);
                        currentTabRef.current = tabRef.current[1];
                        tabScrollRef.current[1].scrollIntoView({
                            behavior: "smooth",
                        });
                        const currentParams = Object.fromEntries(searchParams);
                        delete currentParams?.banner;
                        setSearchParams(new URLSearchParams(currentParams));
                    }, 600);
                }

                const getBuilding = useGetBuildingDetail({ buildingOrigin, pnu });
                getBuilding
                    .then((res) => {
                        const newRes: any = res.data;
                        // buildingOrigin 정보를 받아올 수 없어 total, single에 해당하는 값이 없어 1차로 문제가 발생, 하위 컴포넌트인 buildingDetail에 props(data)로 넘겨주는 항목으로 값이어서 문제가 발생
                        setBuildingDetailData({
                            data: {
                                total: {
                                    ...newRes?.total,
                                    ...building?.data?.total?.[0],
                                    // puri: building?.data?.total?.[0]?.puri,
                                },
                                single: newRes?.single?.map((val: any, idx: number) => ({
                                    ...val,
                                    ...building?.data?.single?.[idx],
                                })),
                            },
                            typeList: res.typeList,
                            totalBuildingTable: building.totalBuildingTable,
                        });
                    })
                    .catch(() => {
                        setBuildingDetailData({
                            data: building?.data,
                            typeList: building?.typeList,
                            totalBuildingTable: building.totalBuildingTable,
                        });
                    });
                if (startStateLand && stateLandInfoRef.current) {
                    setTimeout(() => {
                        stateLandInfoRef.current && stateLandInfoRef.current.scrollIntoView({ behavior: "smooth" });
                    }, 500);
                }
            }

            setItemType(building?.origin?.single[0]?.regstrKindCdNm || "토지");
        }
    }, [isApiCall, cardData]);

    useEffect(() => {
        setCardType(type);
    }, [type]);

    const onOpenPdfHandler = async (type: string) => {
        const { pnu, land } = cardData;
        const deungiCount = await useDeungiCountByPnu(pnu);

        switch (type) {
            case "토지":
                if (deungiCount.data.groundCnt === 0) {
                    setPopupToggle(true);
                } else {
                    const params = JSON.stringify({ pnu, address: land.address, isLand: true });
                    transferToNative(params, "showDeungiPnuView");
                }
                break;
            case "집합건물":
            case "일반건물":
                if (deungiCount.data.buildingCnt === 0) {
                    setPopupToggle(true);
                } else {
                    const params = JSON.stringify({ pnu, address: land.address, isLand: false });
                    transferToNative(params, "showDeungiPnuView");
                }
                break;
        }
    };

    //* 필지 카운트 작업
    const { data: userInfo, refetch: refetchUserInfo } = useUserInfo();

    useEffect(() => {
        refetchUserInfo();
    }, []);

    const updateDetailCount = useUpdateFieldDetailCount();

    useEffect(() => {
        if (cardData?.pnu && !isLoading) {
            refetchUserInfo().then((res) => {
                if (res.isFetched) {
                    const userNo = res?.data?.user_no;
                    const newParam = {
                        cookieNo: userNo ?? "",
                        pnu: cardData?.pnu ?? "",
                    };
                    updateDetailCount.mutate(newParam);
                }
            });
        }
    }, [cardData, isLoading]);

    const CardArea = useMemo(() => {
        if (
            cardType === "land" ||
            (!buildingCardProps.buildingCardContents?.origin?.total?.length &&
                !buildingCardProps.buildingCardContents?.origin?.single?.length)
        ) {
            return (
                <div className="land_card card">
                    <div className="top">
                        <span style={{ fontWeight: "400" }}>{address ?? cardData.land?.address}</span>
                    </div>
                    <div className="btm">
                        <div style={{ marginBottom: "4px" }}>
                            {/* {isShowLabel(cardData.land?.lndcgrCodeNm) && <span> {cardData.land?.lndcgrCodeNm}</span>} */}
                            {isShowLabel(fieldJimok) && <span> {fieldJimok}</span>}
                            {isShowLabel(cardData.land?.use) && <span>{cardData.land?.use}</span>}
                        </div>
                        <div>
                            {/* {(isShowLabel(areaSize?.pyong ?? "") || isShowLabel(cardData.land?.size)) && (
                                <span>토지면적 {areaSize?.pyong ?? cardData.land?.size}</span>
                            )} */}
                            {(isShowLabel(areaSize?.pyong ?? "") || isShowLabel(cardData.land?.size)) && (
                                <span>토지면적 {cardData.land?.size ?? areaSize?.pyong}</span>
                            )}
                            {isShowLabel(cardData.land?.owner) && <span> {cardData.land?.owner}</span>}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div css={buildingCard}>
                    <BuildingCard
                        titleRef={buildingCardProps.titleRef}
                        isBackToAppShow={buildingCardProps.isBackToAppShow}
                        handleClickBackToApp={buildingCardProps.handleClickBackToApp}
                        handleClickRoadViewButton={buildingCardProps.handleClickRoadViewButton}
                        buildingCardContents={buildingCardProps.buildingCardContents}
                        landCardContents={buildingCardProps.landCardContents}
                        hideLandCard={buildingCardProps.hideLandCard}
                        address={address}
                        convertLandCard={() => {
                            setCardType("building");
                        }}
                        fieldJimok={fieldJimok}
                        showTopBtn={false}
                    />
                </div>
            );
        }
    }, [cardData, areaSize]);

    const TopNavi = useMemo(
        () => (
            <nav css={tabNav}>
                <ul>
                    {TAB_TITLES.map((title: string, index: number) => {
                        let addActiveClass = () => {
                            let ACTIVE = "active";
                            let INACTIVE = "inactive";
                            if (currentTab === tabRef?.current[index]) {
                                return ACTIVE;
                            } else {
                                return INACTIVE;
                            }
                        };
                        return (
                            <li
                                key={index}
                                ref={(el) => (navRef.current[index] = el)}
                                className={addActiveClass()}
                                onClick={() => {
                                    setCurrentTab(tabRef.current[index]);
                                    checkScrollRef.current = true;
                                    currentTabRef.current = tabRef.current[index];
                                    tabScrollRef.current[index].scrollIntoView({
                                        behavior: "smooth",
                                    });
                                    setTimeout(() => {
                                        checkScrollRef.current = false;
                                    }, 1000);
                                }}
                            >
                                {title}
                            </li>
                        );
                    })}
                </ul>
            </nav>
        ),
        [currentTab],
    );

    const RealPriceBox = useMemo(
        () => (
            <div className="detailWrap">
                <div className="scrollAnchor" ref={(el) => (tabScrollRef.current[0] = el)}></div>
                <div>
                    <RealPriceDetail
                        data={realPriceDetailData}
                        apiParams={RealPriceApiParams.current}
                        realPriceRef={realPriceRef}
                    />
                </div>
            </div>
        ),
        [realPriceDetailData, cardData],
    );
    const LandDetailBox = useMemo(
        () => (
            <div className="detailWrap">
                <div className="scrollAnchor" ref={(el) => (tabScrollRef.current[1] = el)}></div>
                <LandDetail
                    data={landDetailData}
                    pnu={cardData.pnu}
                    landInfoRef={landInfoRef}
                    stateLandInfoRef={stateLandInfoRef}
                    areaSize={areaSize}
                    fieldJimok={fieldJimok}
                    eumMapLinks={eumMapLinks}
                />
            </div>
        ),
        [landDetailData, cardData, areaSize, fieldJimok],
    );

    const onBuildingRegisterHandler = async () => {
        // 웹뷰 내 이동
        // navigate(
        //     {
        //         pathname: "/buildingRegister",
        //         search: `?address=${cardData.land?.address}&splotNm=&block=`,
        //     },
        //     { replace: false },
        // );

        const params = JSON.stringify({ address: cardData.land?.address, splotNm: "", block: "" });
        transferToNative(params, "showBuildingRegisterView");
    };

    const BuildingBox = useMemo(
        () => (
            <div className="detailWrap">
                <div className="scrollAnchor" ref={(el) => (tabScrollRef.current[2] = el)}></div>
                <div css={{ height: "10px" }} ref={buildingRef} />
                <div>
                    {type === "building" && !isBuildingNone ? (
                        <BuildingDetail
                            data={buildingDetailData.data}
                            typeList={buildingDetailData.typeList}
                            pnu={cardData.pnu}
                            checked={checked}
                            onBuildingRegisterHandler={onBuildingRegisterHandler}
                        />
                    ) : (
                        <div className="buildingBox">
                            <Title title="건물정보" size={"large"} />
                            <div css={nullBox}>건물 정보가 존재하지 않습니다</div>
                        </div>
                    )}
                </div>
            </div>
        ),
        [buildingDetailData, checked, cardData, type, puriInfo, isBuildingNone],
    );

    const DeunggiBox = useMemo(
        () => (
            <div className="buttonsWrap">
                <div>
                    <Title title="등기부등본 열람" size={"large"} />
                    <div className="scrollAnchor" ref={(el) => (tabScrollRef.current[3] = el)}></div>
                    <p ref={deungiRef}></p>
                    <div className="buttons">
                        {itemType === "토지" && <button onClick={() => onOpenPdfHandler("토지")}>토지등기열람</button>}
                        {itemType === "집합" && <button onClick={() => onOpenPdfHandler("집합건물")}>건물등기열람</button>}
                        {itemType === "일반" && (
                            <>
                                <button onClick={() => onOpenPdfHandler("일반건물")}>건물등기열람</button>
                                <button onClick={() => onOpenPdfHandler("토지")}>토지등기열람</button>
                            </>
                        )}
                    </div>
                    <DeungiPopup
                        address={address}
                        type={popupType}
                        toggle={popupToggle}
                        setToggle={setPopupToggle}
                        data={{ address: cardData.land?.address }}
                    />
                </div>
            </div>
        ),
        [cardData, itemType, popupType, popupToggle],
    );

    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <>
            {isLoading && (
                <LoadingWrapper>
                    <img src={AD_IMAGE} />
                </LoadingWrapper>
            )}
            <div css={pageWrap({ isLoading })}>
                <header
                    onClick={(evt) => {
                        !(evt.target === shareRef.current || evt.target === shareImgRef.current) && handleSwipDown();
                    }}
                >
                    <img
                        src={backIcon}
                        alt=""
                        onClick={() => {
                            handleSwipDown();
                            if (from === "app") {
                                transferToNative("done", "closeWebView");
                                return;
                            }
                        }}
                    />
                    <span>위치분석</span>
                    <button onClick={() => onClickShare()} ref={shareRef}>
                        <img src={shareIcon} alt="" className="share" ref={shareImgRef} />
                    </button>
                </header>
                <section ref={data}>
                    <article>
                        <div css={cardWrap}>{CardArea}</div>
                    </article>
                    <div css={ButtonBox}>
                        <div css={landPlanButtonBoxStyle}>
                            <button
                                onClick={() => {
                                    const params = {
                                        title: "토지계획도면",
                                        url: eumMapLinks.land_use_plan_map,
                                    };
                                    transferToNative(JSON.stringify(params), "openExternalMap");
                                }}
                                css={landPlanButtonStyle}
                            >
                                토지계획도면
                            </button>
                            <button
                                onClick={() => {
                                    const params = {
                                        title: "도시계획도면",
                                        url: eumMapLinks.city_plan_map,
                                    };
                                    transferToNative(JSON.stringify(params), "openExternalMap");
                                }}
                                css={landPlanButtonStyle}
                            >
                                도시계획도면
                            </button>
                        </div>
                        <DeungiOpenButton address={address} size="large" />
                    </div>
                    <nav css={tabNav}>
                        <ul>
                            {TAB_TITLES.map((title: string, index: number) => {
                                let addActiveClass = () => {
                                    let ACTIVE = "active";
                                    let INACTIVE = "inactive";

                                    if (!currentTab && index === 0) {
                                        return ACTIVE;
                                    }

                                    if (currentTab && currentTab === tabRef?.current[index]) {
                                        return ACTIVE;
                                    }
                                    return INACTIVE;
                                };
                                return (
                                    <li
                                        key={title}
                                        ref={(el) => (navRef.current[index] = el)}
                                        className={addActiveClass()}
                                        onClick={() => {
                                            setCurrentTab(tabRef.current[index]);
                                            checkScrollRef.current = true;
                                            currentTabRef.current = tabRef.current[index];
                                            tabScrollRef.current[index].scrollIntoView({
                                                behavior: "smooth",
                                                block: index !== 4 ? "start" : "end",
                                            });
                                            setTimeout(() => {
                                                checkScrollRef.current = false;
                                            }, 1000);
                                        }}
                                    >
                                        {title}
                                    </li>
                                );
                            })}
                        </ul>
                    </nav>
                    {RealPriceBox}
                    {LandDetailBox}
                    {BuildingBox}
                    {DeunggiBox}
                </section>
            </div>
        </>
    );
}

const LoadingWrapper = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow-y: hidden;
    z-index: 999;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`;
const pageWrap = (props: any) => css`
    width: 100%;
    height: 100%;
    /* overflow: auto; */
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 998;
    .titleBoxWithButton {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    header {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        height: 56px;
        line-height: 56px;
        background-color: #fff;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 20px;
        opacity: ${props.isLoading ? 0 : 1};
        visibility: ${props.isLoading ? "hidden" : "visible"};
        img {
            width: 10px;
            height: 17px;
            justify-self: start;
        }
        span {
            display: block;
            margin-left: 25px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
            font-size: 18px;
            line-height: 28px;
            color: ${Common.colors.navyPrimary};
            justify-content: center;
        }
        button {
            padding: 0px 25px 0px 20px;
            height: 100%;
        }
        button > .share {
            width: inherit;
        }
    }
    & > section {
        height: 100%;
        overflow-y: scroll;
        overflow-x: hidden;
        -ms-overflow-style: none;
    }
    & > section::-webkit-scrollbar {
        display: none;
    }
    .detailWrap {
        width: 100%;
        position: relative;
        .buildingBox {
            .title {
                padding-left: 14px;
            }
            border-bottom: 6px solid ${Common.colors.grey02};
        }
    }
    .scrollAnchor {
        width: 100%;
        height: 120px;
        position: absolute;
        left: 0;
        top: -120px;
        z-index: -1;
    }
    .buttonsWrap {
        padding: 14px;
        position: relative;
        p {
            margin: 12px 0;
            display: flex;
            align-items: flex-start;
            column-gap: 8px;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            img {
                width: 18px;
                height: 18px;
            }
            & > span {
                & > label {
                    font-weight: 700;
                }
            }
        }
        .buttons {
            display: flex;
            align-content: center;
            justify-content: space-between;
            column-gap: 10px;
            button {
                flex: 1;
                height: 56px;
                text-align: center;
                border-radius: 8px;
                background-color: ${Common.colors.blue};
                font-style: normal;
                font-weight: 700;
                font-size: 18px;
                line-height: 28px;
                color: ${Common.colors.white};
            }
        }
    }
`;
const mapWrap = css`
    width: 100%;
    height: 226px;
    background-color: #ddd;
    text-align: center;
    margin-top: 56px;
`;
const cardWrap = css`
    margin-top: 56px;
    .summCard {
        display: block !important;
    }
    padding: 14px;
    .card {
        .top {
            padding-bottom: 14px;
            border-bottom: 1px solid ${Common.colors.grey03};

            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            color: ${Common.colors.normalTextColor};
            span {
                display: block;
            }
        }
        .btm {
            margin-top: 4px;
            padding-top: 14px;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${Common.colors.darkGrey03};
            span {
                display: inline-block;
                margin-right: 14px;
            }
            li:first-of-type {
                margin-bottom: 4px;
            }
        }
    }
    .building_card .top {
        span:first-of-type {
            margin-bottom: 4px;
        }
        & > div span {
            display: inline-block;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${Common.colors.darkGrey04};
            &.badge {
                margin-right: 8px;
                padding: 0 4px;
                height: 20px;
                line-height: 20px;
                border: 1px solid ${Common.colors.redSecondary};
                border-radius: 4px;
                font-weight: 400;
                font-size: 12px;
                color: ${Common.colors.redSecondary};
            }
        }
    }
`;
const tabNav = css`
    margin-top: 10px;
    padding: 14px;
    position: sticky;
    left: 0;
    top: 44px;
    z-index: 8;
    background-color: #fff;
    ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: ${Common.colors.grey01};
        height: 52px;
        border-radius: 8px;

        li.inactive {
            flex: 1;
            text-align: center;
            border-radius: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;
            line-height: 52px;
            height: 52px;
            color: ${Common.colors.darkGrey03};
        }
        li.active {
            flex: 1;
            text-align: center;
            border-radius: 8px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;
            line-height: 52px;
            height: 52px;
            background: linear-gradient(219.17deg, #1672ff 6.51%, #0030ff 76.16%, #0042ff 100.03%, #001eaa 100.03%);
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.14);

            color: #fff;
            font-weight: 900;
            a {
                color: #fff;
            }
        }
    }
`;
const nullBox = css`
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: ${Common.colors.greyPrimary};
    background-color: ${Common.colors.grey02};
    border-radius: 14px;
    text-align: center;
    padding: 24px 0;
    margin: 14px;
`;

const buildingCard = css`
    position: relative;

    & > .summCard {
        display: block !important;
        position: relative;
        left: 0;
        padding: unset;
        display: block;
        box-shadow: unset;
        width: 100%;
        z-index: unset;
        min-height: unset;
        .closeBtn,
        .swipePoint,
        .backToApp {
            display: none;
        }

        .address {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
        }
        .badge {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
        }
        .roadAddressText {
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            line-height: 20px;
            color: ${Common.colors.darkGrey04};
        }
        .contents_bottom {
            li {
                padding-bottom: 4px;
                span {
                    padding-right: 6px;

                    font-style: normal;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 20px;
                    color: ${Common.colors.darkGrey03};
                    word-break: keep-all;
                }
            }
        }
    }
    .roadViewButton {
        display: none;
    }
`;

const ButtonBox = css`
    width: 100%;
    padding: 0 14px;
`;

const landPlanButtonBoxStyle = css`
    display: flex;
    gap: 10px;
    padding: 10px 0px;
`;

const landPlanButtonStyle = css(
    mq({
        width: "100%",
        borderRadius: "4px",
        backgroundColor: "#5fcf7e",
        padding: "8px 14px",
        color: "#ffffff",
        textAlign: "center",
        fontSize: ["16px", "10px", "14px"],
        fontWeight: "400",
        lineHeight: "26px",
        cursor: "pointer",
    }),
);

export default FieldWrap;
