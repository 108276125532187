import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import FilterHeader from "@src/components/filter/Header";
import ContentHeader from "@src/components/filter/ContentHeader";
import LocationFilter from "@src/components/filter/LocationFilter";
import useGetLocations from "@src/hooks/filter/useGetLocations";
import FilterFooter from "@src/components/filter/FilterFooter";
import useGetPurpose from "@src/hooks/filter/useGetPurpose";
import PurposeFilter from "@src/components/filter/PurposeFilter";
import SelectedFilter from "@src/components/filter/SelectedFilter";
import useSelectedFilter from "@src/hooks/filter/useSelectedFilter";
import CustomDateRangePicker from "@src/components/filter/Calendar";
import { itemForFilterMaster } from "@src/data/itemForFilterMaster";
import { opposingPowerData } from "@src/data/itemForFilterOpposePower";
import { Common } from "@src/styles/Common";
import useRangeSelected from "@src/hooks/filter/useRangeSelected";
import RangeSelected from "@src/components/filter/RangeSelected";
import useDatePickerFilter from "@src/hooks/filter/useDatePickerFilter";
import { axiosInstance } from "@src/api/axiosConfig";
import { axiosPath } from "@src/api/axiosPath";
import { itemForFilterDisposition } from "@src/data/itemForFilterDisposition";
import { itemForFilterAssetType } from "@src/data/itemForFilterAssetType";
import WithoutFilter from "@src/components/filter/WithoutFilter";
import { itemForFilterProgress } from "@src/data/itemForFilterProgress";
import { itemForFilterBidCount } from "@src/data/itemForFilterBidCount";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { initialState, makePriceParam, removeAll, useFilterStore } from "@src/store/filterStore";
import BidCountInput from "@src/components/filter/BidCountInput";
import { makeUseParam } from "@src/data/itemForFilterPurpose";
import AiResetConfirmModal from "./aiFilter/AiResetConfirmModal";
import ToastMessage from "@src/components/filter/ToastMessage";
import { transferToNative } from "@src/lib/WebAppBridge";

export type SelectedAllType = {
    city: boolean;
    dvsn: boolean;
    sec: boolean;
};

export const getTabType: { [key: string]: string } = {
    전체: "0",
    경매: "1",
    공매: "2",
};
export const tabTypeData = ["전체", "경매", "공매"];

const Filter = () => {
    const [withoutState, setWithoutState] = useState(false);
    const [tabState, setTabState] = useState("전체");
    const [bidCount, setBidCount] = useState({
        minCount: "",
        maxCount: "",
    });
    const [resetModalVisible, setResetModalVisible] = useState(false);
    const setUpdateHistoryFilterFlag = useFilterStore((state) => state.setUpdateHistoryFilterFlag);
    const filterState = useFilterStore((state) => state.filterState);
    const [filterParams, setFilterParams] = useState<any>(filterState);

    const { state } = useLocation();

    const onChange = (e: any) => {
        const { value, name } = e.target;
        const isNumber = /^[0-9]+$/;
        const numberValue = Number(value);

        if (value !== "") {
            if (!isNumber.test(value)) return;
            if (!isNumber.test(value) && numberValue > 99) return;
        }

        setBidCount({
            ...bidCount,
            [name]: String(value),
        });
    };

    useEffect(() => {
        setFilterParams(filterState);
        setUpdateHistoryFilterFlag(false);

        setBidCount({
            minCount: filterState.skp_cnt[0],
            maxCount: filterState.skp_cnt[1],
        });
        setWithoutState(filterState.auction_master_except);

        if (filterState.type === "1") {
            setTabState("경매");
        }
        if (filterState.type === "2") {
            setTabState("공매");
            filterState.assets.forEach((item) => {
                onSelectedAssetItem(item);
            });
        }
    }, [filterState]);

    const navigate = useNavigate();

    // 소재지
    const {
        locationData: locationState,
        activeData,
        selectedType,
        handleSelect,
        selectedAll,
        onClickRemove,
        setActiveData,
        selectedParams,
        setSelectedParams,
        resetActiveData,
        activePNUState,
        setActivePNUState,
        resetLocationData,
        getActiveData,
        setSubmitPNUData,
        toastVisible,
        setToastVisible,
    } = useGetLocations(filterParams?.loc, "noReal");

    useEffect(() => {
        getActiveData();
    }, []);

    //용도
    const {
        handleSelect: handlePurposeSelect,
        purposeState,
        query,
        handleSelectAll,
        resetPurpose,
        onClickRemove: onClickRemovePurpose,
    } = useGetPurpose(filterParams?.use, "noReal");

    // 대항력
    const {
        selectedState: opposingPowerState,
        onSelectedItem: onSelectedOpposingPowerItem,
        onClickReset: onClickResetOpposingPower,
    } = useSelectedFilter(opposingPowerData, filterParams?.counter_force);

    // 경매고수물건
    const {
        selectedState: itemForFilterMasterState,
        onSelectedItem: onSelectedMasterItem,
        onClickReset: onClickResetMaster,
    } = useSelectedFilter(itemForFilterMaster, filterParams?.auction_master_item, "noReal");

    // 처분방식
    const {
        selectedState: itemForFilterDispositionState,
        onSelectedItem: onSelectedDispositionItem,
        onClickReset: onClickResetDisposition,
    } = useSelectedFilter(itemForFilterDisposition, filterParams.sales_method);

    // 유찰횟수
    const {
        selectedState: itemForFilterBidCountState,
        onSelectedItem: onSelectedBidCount,
        onClickReset: onClickResetBidCount,
    } = useSelectedFilter(itemForFilterBidCount, filterParams.skp_type);

    // 진행상태
    const {
        selectedState: itemForFilterProgressState,
        onSelectedItem: onSelectedProgressItem,
        onClickReset: onClickResetProgress,
    } = useSelectedFilter(itemForFilterProgress, filterParams.status, "noReal");

    // 자산구분
    const {
        selectedState: itemForFilterAssetState,
        onSelectedItem: onSelectedAssetItem,
        onClickReset: onClickResetAsset,
    } = useSelectedFilter(itemForFilterAssetType, filterParams.assets, "noReal");

    // 매각기일
    const {
        monthState,
        state: dateState,
        setState: setDateState,
        onChangeMonthState,
        onClickResetDate,
    } = useDatePickerFilter(filterParams.due_date);

    // 감정가
    const {
        startValue: appraisedPriceStartValue,
        endValue: appraisedPriceEndValue,
        onClickValue: onClickAppraisedValue,
        onClickReset: onClickAppraisedReset,
    } = useRangeSelected(filterParams.est_price);

    // 최저입찰가
    const {
        startValue: lowBidPriceStartValue,
        endValue: lowBidPriceEndValue,
        onClickValue: onClickLowBidPriceValue,
        onClickReset: onClickLowBidPriceReset,
    } = useRangeSelected(filterParams.min_price);

    useEffect(() => {
        if (tabState !== "공매") {
            onClickResetDisposition();
            onClickResetAsset();
        }
    }, [tabState]);

    const setStoreFilterState = useFilterStore((state) => state.setStoreFilterState);

    const onSubmit = async (params: any) => {
        for (let key in params) {
            if (key !== "use") {
                (params[key] as any).map((item: string, index: any) => {
                    if (item.includes("all")) {
                        params[key][index] = item.replace("all", "");
                    }

                    return params[key];
                });
            }
        }

        const resultParams = {
            ...params,
            ...filterState,
            type: getTabType[tabState],
            use: makeUseParam(params.use),
            loc: removeAll(params.loc),
            auction_master_except: withoutState,
            counter_force: params.counter_force[0],
            sales_method: params.sales_method[0],
            skp_type: params.skp_type[0],
            auction_master_item: params.auction_master_item[0] === "0" ? [] : params.auction_master_item,
            assets: params.assets[0] === "0" ? [] : params.assets,
            status: params.status[0] === "0" ? ["0"] : params.status,
            skp_cnt: [bidCount.minCount, bidCount.maxCount],
            est_price: makePriceParam(params.est_price),
            min_price: makePriceParam(params.min_price),
        };

        const viewParams = {
            ...params,
            type: getTabType[tabState],
            use: params.use,
            loc: params.loc,
            auction_master_except: withoutState,
            counter_force: params.counter_force[0],
            sales_method: params.sales_method[0],
            skp_type: params.skp_type[0],
            auction_master_item: params.auction_master_item[0] === "0" ? [] : params.auction_master_item,
            assets: params.assets[0] === "0" ? [] : params.assets,
            status: params.status[0] === "0" ? ["0"] : params.status,
            skp_cnt: [bidCount.minCount, bidCount.maxCount],
            est_price: makePriceParam(params.est_price),
            min_price: makePriceParam(params.min_price),
        };

        try {
            setStoreFilterState(viewParams, true);
            await axiosInstance.post(axiosPath.postFilter, { filter: resultParams });
            navigate(state, { state: { status: resultParams?.sort } });
        } catch (e) {
            console.log("에러랍니다 에러 ㅋㅋ");
        }
    };

    const onClickReset = () => {
        resetActiveData();
        handlePurposeSelect("전체", "중분류", undefined, "All");
        resetPurpose();
        onClickResetOpposingPower();
        onClickResetMaster();
        onClickResetDisposition();
        onClickResetAsset();
        onClickResetProgress();
        onClickResetBidCount();
        onClickResetDate();
        onClickAppraisedReset();
        onClickLowBidPriceReset();
        setWithoutState(false);
        setResetModalVisible(false);
        setTabState("전체");
        setBidCount({
            minCount: "",
            maxCount: "",
        });
        setStoreFilterState({
            ...filterState,
            type: "0",
            loc: [],
            use: [],
            counter_force: "0",
            auction_master: [],
            auction_master_item: [],
            auction_master_except: false,
            except_item: [],
            status: ["1", "2"],
            skp_type: "",
            skp_cnt: ["", ""],
            due_date: ["", ""],
            est_price: ["", ""],
            min_price: ["", ""],
            sales_method: "0",
            assets: [],
        });
    };

    return (
        <Container>
            <FilterHeader>검색 설정</FilterHeader>
            <Tab>
                {tabTypeData.map((item, index) => (
                    <TabTypeButton isActiveTab={item === tabState} key={index} onClick={() => setTabState(item)}>
                        <span>{item}</span>
                    </TabTypeButton>
                ))}
            </Tab>
            <StyledInfoText>
                <div className="text">
                    예정매물 필터는 &nbsp;<span>{`[메뉴판 > 예정매물]`}</span>에서 확인하실 수 있어요
                </div>
            </StyledInfoText>
            {tabState === "공매" ? (
                <>
                    <ContentHeader
                        title="처분방식"
                        buttonText="전체"
                        onClick={onClickResetDisposition}
                        state={itemForFilterDispositionState}
                    />
                    <SelectedFilter
                        selectedState={itemForFilterDispositionState}
                        onSelectedItem={onSelectedDispositionItem}
                        isMulti={false}
                    />

                    <ContentHeader
                        title="자산구분"
                        buttonText="전체"
                        onClick={onClickResetAsset}
                        state={itemForFilterAssetState}
                    />
                    <SelectedFilter selectedState={itemForFilterAssetState} onSelectedItem={onSelectedAssetItem} />
                </>
            ) : (
                <></>
            )}

            <ContentHeader title="소재지" />
            <div style={{ height: 290 }}>
                <LocationFilter
                    handleSelect={handleSelect}
                    activeData={activeData}
                    locationData={locationState}
                    selectedType={selectedType}
                    setActiveData={setActiveData}
                    resetActiveData={resetActiveData}
                    activePNUState={activePNUState}
                    setActivePNUState={setActivePNUState}
                    resetLocationData={resetLocationData}
                />
            </div>

            <ContentHeader title="용도" />
            <div style={{ height: 290 }}>
                <PurposeFilter
                    handleSelectAll={handleSelectAll}
                    handleSelect={handlePurposeSelect}
                    purposeState={purposeState}
                    query={query}
                />
            </div>

            <ContentHeader title="대항력" buttonText="전체" onClick={onClickResetOpposingPower} state={opposingPowerState} />
            <SelectedFilter selectedState={opposingPowerState} onSelectedItem={onSelectedOpposingPowerItem} isMulti={false} />

            <ContentHeader title="경매고수물건" buttonText="전체" onClick={onClickResetMaster} state={itemForFilterMasterState} />
            <SelectedFilter selectedState={itemForFilterMasterState} onSelectedItem={onSelectedMasterItem} />
            <WithoutFilter withoutState={withoutState} setWithoutState={setWithoutState} />

            <ContentHeader title="진행상태" buttonText="전체" onClick={onClickResetProgress} state={itemForFilterProgressState} />
            <SelectedFilter selectedState={itemForFilterProgressState} onSelectedItem={onSelectedProgressItem} />

            <ContentHeader title="유찰횟수" buttonText="전체" onClick={onClickResetBidCount} state={itemForFilterBidCountState} />
            <SelectedFilter selectedState={itemForFilterBidCountState} onSelectedItem={onSelectedBidCount} isMulti={false} />
            {itemForFilterBidCountState[2].isActive && <BidCountInput bidCount={bidCount} onChange={onChange} />}

            <ContentHeader title="매각기일" />
            <CustomDateRangePicker
                monthState={monthState}
                state={dateState}
                setState={setDateState}
                onChangeMonthState={onChangeMonthState}
                type="setStore"
            />

            <ContentHeader title="감정가" />
            <RangeSelected
                startValue={appraisedPriceStartValue?.value || ""}
                endValue={appraisedPriceEndValue?.value || ""}
                onClickValue={onClickAppraisedValue}
            />

            <ContentHeader title="최저입찰가" />
            <RangeSelected
                startValue={lowBidPriceStartValue?.value || ""}
                endValue={lowBidPriceEndValue?.value || ""}
                onClickValue={onClickLowBidPriceValue}
            />

            <FilterFooter
                onSubmit={onSubmit}
                onClickReset={() => setResetModalVisible(true)}
                onClickRemove={onClickRemove}
                onClickRemovePurpose={onClickRemovePurpose}
                locationState={activeData}
                purposeState={purposeState}
                opposingPowerState={opposingPowerState}
                itemForFilterMasterState={itemForFilterMasterState}
                dateState={dateState}
                appraisedPrice={{
                    appraisedPriceStartValue,
                    appraisedPriceEndValue,
                }}
                lowBidPrice={{
                    lowBidPriceStartValue,
                    lowBidPriceEndValue,
                }}
                itemForFilterAssetState={itemForFilterAssetState}
                itemForFilterProgressState={itemForFilterProgressState}
                itemForFilterBidCountState={itemForFilterBidCountState}
                itemForFilterDispositionState={itemForFilterDispositionState}
                setLocationSelectedParams={setSelectedParams}
                onClickAppraisedReset={onClickAppraisedReset}
                onClickLowBidPriceReset={onClickLowBidPriceReset}
                onClickResetDate={onClickResetDate}
                onClickResetMaster={onClickResetMaster}
                onClickResetOpposingPower={onClickResetOpposingPower}
                onClickResetAsset={onClickResetAsset}
                onClickResetBidCount={onClickResetBidCount}
                onClickResetProgress={onClickResetProgress}
                onClickResetDisposition={onClickResetDisposition}
                setSubmitPNUData={setSubmitPNUData}
                withoutState={withoutState}
                bidCount={bidCount}
            />
            <ToastMessage visible={toastVisible} setToastVisible={setToastVisible}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
            {resetModalVisible ? (
                <AiResetConfirmModal
                    text="매물필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={onClickReset}
                />
            ) : (
                <></>
            )}
        </Container>
    );
};
export default Filter;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    padding-top: 56px;
    padding-bottom: 300px;
    overflow-y: scroll;
`;

export const Tab = styled.div`
    width: 100%;
    height: 56px;
    padding: 0 20px;
    display: flex;
`;

export const TabTypeButton = styled.button<{ isActiveTab: boolean }>`
    flex: 1;
    background: ${({ isActiveTab }) => (isActiveTab ? Common.colors.aucMarkerColor : "#fdfafa")};
    border-radius: 8px;
    span {
        font-size: 16px;
        line-height: 26px;
        font-weight: 700;
        color: ${({ isActiveTab }) => (isActiveTab ? "#FFFFFF" : "black")};
    }
`;

const StyledInfoText = styled.div`
    width: 100%;
    padding: 14px 14px 2px 14px;
    .text {
        height: 38px;
        border-radius: 24px;
        background-color: ${Common.colors.blue01};
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${Common.colors.normalTextColor};
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
    }
    span {
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
    }
`;
