import { axiosInstance } from "@src/api/axiosConfig";
import { useInfiniteQuery, useQuery } from "react-query";

const nestPath = process.env.REACT_APP_NEST_API_URL;

type DepositDetailItemsProps = {
    itemId: string;
};

type DepositDetailItemsResponse = {
    lesseesDepositDetails: [
        {
            occupier: "string";
            occuPart: "string";
            depositDetails: "string";
            feeDetails: "string";
        },
    ];
};

const getDepositDetailItems = async (props: DepositDetailItemsProps) => {
    const res = await axiosInstance.get<DepositDetailItemsResponse>(`${nestPath}/items/detail/${props.itemId}/lessees/deposit`);

    return res;
};

export const useDepositDetail = (props: DepositDetailItemsProps) => {
    return useQuery({
        queryKey: ["depositDetailItems", props.itemId],
        queryFn: () => getDepositDetailItems({ itemId: props.itemId }),
    });
};
