import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import ico_filter_white from "@src/assets/ico_filter_white.png";
import ico_filter_gray from "@src/assets/ico_filter_gray.png";

import { Common } from "@src/styles/Common";

import ToastMessage from "@src/components/filter/ToastMessage";
import ResetFilterButton from "@src/components/filter/ResetFilterButton";

import { transferToNative, WebAppBridge } from "@src/lib/WebAppBridge";

import { useNewFilterStore } from "@src/store/newFilterStore";
import { itemForAppraisedPrice } from "@src/data/itemForAppraisedPrice";

import DueDateFilter from "@src/pages/newFilter/DueDateFilter";
import EstPriceFilter from "@src/pages/newFilter/EstPriceFilter";
import MinPriceFilter from "@src/pages/newFilter/MinPriceFilter";
import AiResetConfirmModal from "@src/pages/aiFilter/AiResetConfirmModal";
import AuctionTypeFilter from "@src/pages/newFilter/AuctionTypeFilter";
import LandUseFilter from "@src/pages/newFilter/LandUseFilter";
import AuctionMasterItemFilter from "@src/pages/newFilter/AuctionMasterItemFilter";
import AuctionStatusFilter, { findKeyByValueFromStatus } from "@src/pages/newFilter/AuctionStatusFilter";
import AuctionSkipFilter, { auctionSkipList } from "@src/pages/newFilter/AuctionSkipFilter";
import AuctionCounterForceFilter from "@src/pages/newFilter/AuctionCounterForceFilter";
import NewFilterHistory from "@src/pages/newFilter/NewFilterHistory";
import InputSearchingItems from "./InputSearchingItems";
import { useNewItemFilterCheckStore } from "@src/store/newItemFilterStore";
import { useFilterStore, useSidoFilterStore } from "@src/store/filterStore";
import { getAuctionMasterItemsReversed } from "@src/constants/auctionMasterItemFilters";
import SearchItemsLocationFilter from "./SearchItemsLocationFilter";
export interface IFilterState {
    type: number; //0: 전체, 1: 경매, 2: 공매
    sales_method: number; //0: 전체, 1: 매각, 2: 임대
    assets: number[]; //0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산
    loc: string[];
    use: string[];
    counter_force: number;
    auction_master: string[];
    auction_master_item: number[]; //1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경
    except_item: string[];
    status: number[];
    skp_type: number; //0: 전체, 1: 신건, 2: 유찰
    skp_cnt: string[];
    due_date: string[];
    est_price: string[];
    min_price: string[];
    sort: number;
    auction_master_except: boolean;
}

export const initialFilterState: IFilterState = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force: 0,
    auction_master_item: [],
    auction_master_except: false,
    status: [],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    sort: 5,
    auction_master: [],
    except_item: [],
};

const check = {
    type: "경공매 구분(단일값)(0: 전체, 1: 경매, 2: 공매)",
    loc: "소재지(전국: [], 그 외: 소재지의 cortarNo 배열)[]",
    use: "용도(주거용: A~, 토지: B~, 산업용: C~, 상업용: D~, 기타: E01)[]",
    counter_force: "대항력(0: 전체, 1: 대항력 있음, 2: 대항력 없음)",
    auction_master_item:
        "경매고수물건(기존 경매고수물건과 제외물건 합쳐진 필터)(1: 유치권, 2: 분묘기지권, 3: 법정지상권, 4: 위반건축물, 5: 농취증필요, 6: 토지만입찰, 7: 건물만입찰, 8: 토지별도등기, 9: 지분매각, 10: 대지권미등기, 11: HUG 임차권 인수조건 변경, 12: 가등기, 13:가처분)[]",
    auction_master_except: "경매고수물건 제외 여부(true: 제외, false: 포함)",
    status: "진행상태(0: 전체, 1: 진행예정, 2: 진행중, 3: 매각, 4: 기타)[]",
    skp_type: "유찰구분(0: 전체, 1: 신건, 2: 유찰)",
    skp_cnt: "유찰횟수[]",
    due_date: "매각기일(날짜 하나만 선택한 경우, 둘다 같은 날짜로 전달)[]",
    est_price: "감정가[]",
    min_price: "최저입찰가[]",
    sales_method: "처분방식(0: 전체, 1: 매각, 2: 임대)",
    assets: "자산구분(0: 전체, 1: 압류재산, 2: 국공유재산, 3: 신탁재산, 4: 기타재산)[]",
    sort: "정렬(0: 업데이트순, 1: 감정가 높은순, 2: 감정가 낮은순, 3: 최저입찰가 높은순, 4: 최저입찰가 낮은순, 5: 매각기일 가까운순, 6: 매각기일 먼순, 7: 유찰횟수 많은순 8: 유찰횟수 적은순)",
};

interface Props {
    isShowFilter: boolean;
    setIsShowFilter: Dispatch<SetStateAction<boolean>>;
}

export type openOutFilterName =
    | "history"
    | "status"
    | "loc"
    | "type"
    | "use"
    | "counter_force"
    | "auction_master_item"
    | "skp_type"
    | "due_date"
    | "est_price"
    | "min_price";

const SearchInputAndFilter: React.FC<Props> = ({ isShowFilter, setIsShowFilter }) => {
    const filterStoreState = useNewFilterStore((state) => state.newFilterState);
    const setFilterStoreState = useNewFilterStore((state) => state.setStoreNewFilterState);
    const setSubFilterVisible = useNewItemFilterCheckStore((state) => state.setNewItemFilterCheckState);
    const setSelectedSidoForInitialize = useSidoFilterStore((state) => state.setSelectedSido);
    const selectedSido = useFilterStore((state) => state.selectedOutSido);
    const setSelectedSido = useFilterStore((state) => state.setSelectedOutSido);
    const [isOpenRecentKeywordList, setIsOpenRecentKeywordList] = useState(false);
    const [toastVisible, setToastVisible] = useState<boolean>(false);
    const [resetModalVisible, setResetModalVisible] = useState<boolean>(false);
    const [openOutFilter, setOpenOutFilter] = useState<openOutFilterName | "">("");

    WebAppBridge.openFilter = (filterName: openOutFilterName) => {
        setOpenOutFilter(filterName);
    };

    const tabButtons = [
        {
            title: "최근검색",
            key: "history",
            selected: false,
            selectedChild: "",
        },
        {
            title: "진행상태",
            key: "status",
            selected: !!filterStoreState.status.length,
            selectedChild:
                filterStoreState.status.filter((status) => status !== 2).length > 1
                    ? `${findKeyByValueFromStatus(filterStoreState.status[0])} 외 ${
                          filterStoreState.status.filter((status) => status !== 2).length - 1
                      }`
                    : `${findKeyByValueFromStatus(filterStoreState.status[0])}`,
        },
        {
            title: "소재지",
            key: "loc",
            selected: selectedSido !== "all" || filterStoreState.loc.length > 0,
            selectedChild: "소재지",
        },
        {
            title: "경공매",
            key: "type",
            selected: !!filterStoreState.type,
            selectedChild: filterStoreState.type === 1 ? "경매" : "공매",
        },
        {
            title: "용도",
            key: "use",
            selected: !!filterStoreState.use.length,
            selectedChild: "용도",
        },
        {
            title: "대항력",
            key: "counter_force",
            selected: !!filterStoreState.counter_force,
            selectedChild:
                filterStoreState.counter_force === 2
                    ? "대항력 있음"
                    : filterStoreState.counter_force === 1
                    ? "대항력 없음"
                    : "미상",
        },
        {
            title: "경매고수물건",
            key: "auction_master_item",
            selected: !!filterStoreState.auction_master_item.length,
            selectedChild:
                filterStoreState.auction_master_item.length > 1
                    ? `${getAuctionMasterItemsReversed()[filterStoreState.auction_master_item[0]]} 외 ${
                          filterStoreState.auction_master_item.length - 1
                      }${filterStoreState.auction_master_except ? " 제외" : ""}`
                    : `${getAuctionMasterItemsReversed()[filterStoreState.auction_master_item[0]]}${
                          filterStoreState.auction_master_except ? " 제외" : ""
                      }`,
        },
        {
            title: "유찰횟수",
            key: "skp_type",
            selected: !!filterStoreState.skp_type,
            selectedChild:
                `${auctionSkipList[filterStoreState.skp_type]}` +
                `${filterStoreState.skp_type === 2 ? ` ${filterStoreState.skp_cnt[0]}` : ""}` +
                `${
                    filterStoreState.skp_cnt.some((val) => val) && filterStoreState.skp_cnt[0] !== filterStoreState.skp_cnt[1]
                        ? " ~"
                        : ""
                }` +
                `${
                    filterStoreState.skp_type === 2 && filterStoreState.skp_cnt[0] !== filterStoreState.skp_cnt[1]
                        ? ` ${filterStoreState.skp_cnt[1]}`
                        : ""
                }`,
        },
        {
            title: "매각기일",
            key: "due_date",
            selected: !!filterStoreState.due_date.every((val) => val),
            selectedChild:
                filterStoreState.due_date[0] !== filterStoreState.due_date[1]
                    ? `${filterStoreState.due_date[0].slice(2)} ~ ${filterStoreState.due_date[1].slice(2)}`
                    : `${filterStoreState.due_date[0].slice(2)}`,
        },
        {
            title: "감정가",
            key: "est_price",
            selected: !!filterStoreState.est_price.some((val) => val),
            selectedChild:
                `감정가 ${
                    itemForAppraisedPrice.filter(
                        (val) =>
                            val.value ===
                            (filterStoreState.est_price[0] === "30000000000" ? "higher" : filterStoreState.est_price[0]),
                    )?.[0]?.showText ?? ""
                } ~ ` +
                `${
                    filterStoreState.est_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterStoreState.est_price[1])?.[0]?.title
                }`,
        },
        {
            title: "최저입찰가",
            key: "min_price",
            selected: !!filterStoreState.min_price.some((val) => val),
            selectedChild:
                `최저입찰가 ${
                    itemForAppraisedPrice.filter(
                        (val) =>
                            val.value ===
                            (filterStoreState.min_price[0] === "30000000000" ? "higher" : filterStoreState.min_price[0]),
                    )?.[0]?.showText ?? ""
                } ~ ` +
                `${
                    filterStoreState.min_price[1] &&
                    itemForAppraisedPrice.filter((val) => val.value === filterStoreState.min_price[1])?.[0]?.title
                }`,
        },
    ];

    const handleFilterSelectDone = () => {
        setOpenOutFilter("");
    };

    const handleFilterInitialize = () => {
        setSelectedSidoForInitialize("all");
        setFilterStoreState(initialFilterState);
        setResetModalVisible(false);
        setOpenOutFilter("");
    };

    useEffect(() => {
        if (isOpenRecentKeywordList === true) {
            transferToNative("full", "resizeWebview");
        } else {
            transferToNative(openOutFilter ? "full" : "default", "resizeWebview");
        }
    }, [openOutFilter]);

    return (
        <div css={rootStyle(!!openOutFilter)}>
            <div css={rootBoxStyle(!!openOutFilter)}>
                <InputSearchingItems
                    isShowFilter={isShowFilter}
                    setIsShowFilter={setIsShowFilter}
                    openOutFilter={openOutFilter}
                    onFilterOff={handleFilterSelectDone}
                    isOpenRecentKeywordList={isOpenRecentKeywordList}
                    setIsOpenRecentKeywordList={setIsOpenRecentKeywordList}
                />
                <div css={tabListBoxStyle(isShowFilter)}>
                    {!isShowFilter ? (
                        <div css={DisableAllFilter}>
                            <button>
                                <img src={ico_filter_gray} />
                                <span>검색 설정</span>
                            </button>
                        </div>
                    ) : (
                        <Link to="/allFilter" css={allFilterButtonStyle}>
                            <img src={ico_filter_white} />
                            <span>검색 설정</span>
                        </Link>
                    )}
                    <div className="filter_button_scroll_wrapper">
                        {tabButtons.map((item, index) => (
                            <TabButton
                                isSelected={openOutFilter === item.key || item.selected}
                                disabled={!isShowFilter}
                                key={`${item}_${index}`}
                                onClick={() => {
                                    if (openOutFilter === item.key) {
                                        handleFilterSelectDone();
                                    } else {
                                        setSubFilterVisible(true);
                                        setOpenOutFilter(item.key as openOutFilterName);
                                    }
                                }}
                            >
                                <span>{item.selected ? item.selectedChild : item.title}</span>
                            </TabButton>
                        ))}
                    </div>
                </div>
            </div>
            <div>
                {openOutFilter && (
                    <>
                        <div css={dividerStyle} />
                        <div css={whiteDividerStyle} />
                    </>
                )}
                {openOutFilter === "history" && (
                    <NewFilterHistory
                        onClose={() => {
                            setSubFilterVisible(false);
                            setOpenOutFilter("");
                        }}
                    />
                )}
                {openOutFilter === "type" && (
                    <AuctionTypeFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "loc" && (
                    <SearchItemsLocationFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        showToast={() => setToastVisible(true)}
                        from="out"
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "use" && (
                    <LandUseFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        from="out"
                        showToast={() => setToastVisible(true)}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "counter_force" && (
                    <AuctionCounterForceFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "auction_master_item" && (
                    <AuctionMasterItemFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "status" && (
                    <AuctionStatusFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "skp_type" && (
                    <AuctionSkipFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "due_date" && (
                    <DueDateFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "est_price" && (
                    <EstPriceFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter === "min_price" && (
                    <MinPriceFilter
                        filterParams={filterStoreState}
                        setFilterParams={setFilterStoreState}
                        allFilter={false}
                        onClickDone={handleFilterSelectDone}
                    />
                )}
                {openOutFilter && openOutFilter !== "history" && (
                    <ResetFilterButton onResetClick={() => setResetModalVisible(true)} onConfirmClick={handleFilterSelectDone} />
                )}
            </div>
            <ToastMessage visible={toastVisible} setToastVisible={() => setToastVisible(false)}>
                최대 10개까지 선택 가능합니다.
            </ToastMessage>
            {resetModalVisible && (
                <AiResetConfirmModal
                    text="매물필터를 초기화할까요?"
                    onClickCancel={() => setResetModalVisible(false)}
                    onClickConfirm={handleFilterInitialize}
                />
            )}
            {openOutFilter && (
                <div css={{ height: "100%", background: "rgba(0,0,0,1)", opacity: 0.3 }} onClick={handleFilterSelectDone} />
            )}
        </div>
    );
};

export default SearchInputAndFilter;

const rootStyle = (isShowFilterList: boolean) => css`
    width: 100vw;
    height: ${isShowFilterList ? "calc(var(--vh, 1vh) * 100)" : "fit-content"};
    height: ${isShowFilterList ? "100dvh" : "fit-content"};
    height: ${isShowFilterList ? "100vh" : "fit-content"};
`;

const rootBoxStyle = (isShowFilterList: boolean) => css`
    padding: 14px 14px 0;
    background-color: ${isShowFilterList ? "#ffffff" : "rgba(255, 255, 255, 1)"};
`;

const inputBoxStyle = css`
    position: relative;
    width: 100%;
    height: 44px;
    padding: 4px 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const inputStyle = css`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    opacity: 1;
    height: 40px;
    border: 1px solid #e3e3e6;
    border-radius: 8px;
    padding: 0px 10px 0px 48px;
    font-size: 16px;
    background: #ffffff;
    &:focus {
        outline: none;
    }
`;

const searchImageStyle = css`
    position: absolute;
    left: 58px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
`;

const tabListBoxStyle = (isShowFilter: boolean) => css`
    display: flex;
    align-items: center;
    height: ${isShowFilter ? "56px" : "56px"};
    opacity: ${isShowFilter ? 1 : 1};
    visibility: ${isShowFilter ? "visible" : "visible"};
    /* height: ${isShowFilter ? "56px" : "0px"};
    opacity: ${isShowFilter ? 1 : 0};
    visibility: ${isShowFilter ? "visible" : "hidden"}; */
    transition: all 0.2s ease;
    z-index: 3;
    button + button {
        margin-left: 8px;
    }
    & > a {
        & > button {
            opacity: ${isShowFilter ? 1 : 0};
            box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.14);
            visibility: ${isShowFilter ? "visible" : "hidden"};
            height: ${isShowFilter ? "30px !important" : "0px"};
            transition: all 0.2s ease;
        }
    }
    .filter_button_scroll_wrapper {
        display: flex;
        overflow-x: scroll;
        z-index: 2;
        height: ${isShowFilter ? "32px" : "32px"};
        opacity: ${isShowFilter ? 1 : 1};
        visibility: ${isShowFilter ? "visible" : "visible"};
        /* height: ${isShowFilter ? "32px" : "0px"};
        opacity: ${isShowFilter ? 1 : 0};
        visibility: ${isShowFilter ? "visible" : "hidden"}; */
        transition: all 0.2s ease;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const allFilterButtonStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    background: ${Common.colors.aucMarkerColor};
    min-width: 102px;
    border-radius: 24px;
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.white};
    }
    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;

const dividerStyle = css`
    height: 12px;
    background: rgb(236, 239, 244);
`;

const whiteDividerStyle = css`
    height: 10px;
    background-color: #ffffff;
`;

const TabButton = styled.button<{ isSelected: boolean; disabled: boolean }>`
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    border-radius: 24px;
    box-shadow: ${({ disabled }) => (disabled ? 0 : "1px 2px 4px rgba(0, 0, 0, 0.14)")};
    padding: 8px 16px 8px 16px;
    background: ${({ isSelected, disabled }) =>
        disabled ? `${Common.colors.grey02}` : isSelected ? ` ${Common.colors.aucMarkerColor}` : `${Common.colors.white}`};
    white-space: nowrap;

    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${({ isSelected, disabled }) =>
            disabled ? `${Common.colors.grey04}` : isSelected ? ` ${Common.colors.white}` : `${Common.colors.normalTextColor}`};
    }
`;

const DisableAllFilter = css`
    display: flex;
    height: 32px;
    /* background: ${Common.colors.grey02}; */
    min-width: 102px;
    border-radius: 24px;
    & > button {
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        background: ${Common.colors.grey02};
        min-width: 102px;
        border-radius: 24px;
    }
    span {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        color: ${Common.colors.grey04};
    }

    img {
        width: 16px;
        height: 18px;
        margin-right: 6px;
    }
`;
