import { IFilterParamsType } from "@src/pages/newFilter";
import { configurePersist } from "zustand-persist";
import create from "zustand";

type useFilterStoreType = {
    newItemFilterState: IFilterParamsType;
    setStoreNewItemFilterState: (filterState: IFilterParamsType) => void;
};

type useNewFilterCheckStoreType = {
    newItemFilterCheckState: boolean;
    setNewItemFilterCheckState: (status: boolean) => void;
};

const initialFilterParams: IFilterParamsType = {
    type: 0,
    sales_method: 0,
    assets: [],
    loc: [],
    use: [],
    counter_force: 0,
    auction_master_item: [],
    auction_master_except: false,
    status: [1, 2],
    skp_type: 0,
    skp_cnt: ["", ""],
    due_date: ["", ""],
    est_price: ["", ""],
    min_price: ["", ""],
    sort: 5,
    auction_master: [],
    except_item: [],
};

const { persist } = configurePersist({
    storage: localStorage, // use `AsyncStorage` in react native
    rootKey: "newFilterState", // optional, default value is `root`
});

export const useNewItemFilterStore = create<useFilterStoreType>(
    // persist(
    //     {
    //         key: "newFilterState",
    //         allowlist: ["newFilterState"],
    //         denylist: [],
    //     },
    (set: any) =>
        ({
            newItemFilterState: initialFilterParams,
            setStoreNewItemFilterState: async (filterState: any) => {
                set(() => {
                    return {
                        newItemFilterState: {
                            ...filterState,
                        },
                    };
                });
            },
        } as any),
    // ) as any,
);

export const useNewItemFilterCheckStore = create<useNewFilterCheckStoreType>(
    (set: any) =>
        ({
            newItemFilterCheckState: false,
            setNewItemFilterCheckState: async (status: boolean) => {
                set(() => {
                    return {
                        newItemFilterCheckState: status,
                    };
                });
            },
        } as any),
);
