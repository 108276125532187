import { css } from "@emotion/react";
import styled from "@emotion/styled";
import useTestBottomSheet from "@src/hooks/bottomSheet/useBottomSheet";
import useGetFieldContents from "@src/hooks/map/useGetFieldContents";
import useGetRoadAddressFromCoords from "@src/hooks/map/useMapCoordToRoadAddr";
import useIsLive from "@src/hooks/useIsLive";
import { useMapBottomSheetStore } from "@src/store/MapBottomSheetStore";
import React, { useEffect, useMemo, useState } from "react";
import CardBottomSheetHeader from "./CardBottomSheetHeader";
import CardDataSheet from "./CardDataSheet";
import { useGetMapFieldInformation } from "@src/hooks/map/useGetMapPolyCoordInternalization";
import { useSearchParams } from "react-router-dom";
import { useFindOneAreaSize } from "@src/hooks/map/useFindRoadWidth";

export const landTypes = ["전", "답", "목장용지", "임야", "하천", "유지", "공원", "사적지", "도로"];

type CardType = "land" | "building";
const isLive = useIsLive();
interface CardFieldDetailProps {
    // handleSwipeUp: () => void;
    type: CardType;
    location: naver.maps.LatLng;
    isBackToAppShow: boolean;
    handleClickBackToApp: () => void;
    handleClickRoadViewButton: (position: naver.maps.LatLng) => void;
    hideLandCard: () => void;
    address: string;
    pnu: string;
    isAutoShow: boolean;
    onChangePnuFromApp: () => void;
    roadAddr?: string;
    // !임시로 빌딩 평형 정보 넣기(다른 사람 일정상 임의 작업 추후 수정 필요
    buildingSize?: any;
    fieldJimok?: string;
    handleClickBackToAiChat: () => void;
    fromCheck: string;
}

function Card({
    // handleSwipeUp,
    type,
    location,
    isBackToAppShow,
    handleClickBackToApp,
    handleClickRoadViewButton,
    hideLandCard,
    address,
    pnu,
    isAutoShow,
    onChangePnuFromApp,
    roadAddr,
    buildingSize,
    fieldJimok = "",
    fromCheck,
    handleClickBackToAiChat,
}: CardFieldDetailProps) {
    const TYPE_LAND = "land";
    const TYPE_BUILDING = "building";
    const [searchParams] = useSearchParams();
    const [cardType, setCardType] = useState<CardType>(TYPE_LAND);

    const { sheet, titleRef, content, data, wrapperRef, handleSwipDown, checked, onChangeChecked } = useTestBottomSheet(true);

    //* areaSize
    const { data: areaSize, refetch: refetchAreaSize } = useFindOneAreaSize(pnu);

    const isEmptyBuildingData = (data: any) => {
        if (
            data?.buildingNm === "-" &&
            data?.mainUse === "-" &&
            data?.platArea === "-" &&
            data?.totArea === "-" &&
            data?.ugrndFlrCnt === "B0" &&
            data?.grndFlrCnt === "0F" &&
            data?.useAprDay === "-"
        )
            return true;

        return false;
    };

    const wrapperHeight = useMapBottomSheetStore((state) => state.wrapperHeight);
    const setWrapperHeight = useMapBottomSheetStore((state) => state.setWrapperHeight);
    useEffect(() => {
        if (isAutoShow) {
            onChangeChecked();
            sheet.current.style.setProperty("transform", `translateY(${wrapperHeight - window.innerHeight}px)`);
            content.current.style.setProperty("border-radius", "0");
            content.current.style.setProperty("opacity", "1");
            onChangePnuFromApp();
        } else {
            setWrapperHeight(sheet.current.offsetHeight);
        }
    }, [wrapperHeight]);
    const [landCardContents, setLandCardContents] = useState<any>();
    const [buildingCardContents, setBuildingCardContents] = useState<any>();

    const [allFieldData, setAllFieldData] = useState({
        land: {},
        building: {},
        pnu,
        totalBuildingTable: [],
    });

    const matchCardType = (lndcgrCodeNm: string): CardType => {
        const landTypes = ["전", "답", "목장용지", "임야", "하천", "유지", "공원", "사적지", "도로"];
        const buildingTypes = [
            "대",
            "대지",
            "공장용지",
            "학교용지",
            "창고용지",
            "종교용지",
            "유원지",
            "체육용지",
            "과수원",
            "광천지",
            "주차장",
            "주유소용지",
            "철도용지",
            "제방",
            "구거",
            "양어장",
            "수도용지",
            "묘지",
            "잡종지",
        ];
        if (landTypes.includes(lndcgrCodeNm)) return TYPE_LAND;
        if (buildingTypes.includes(lndcgrCodeNm)) return TYPE_BUILDING;

        return TYPE_LAND;
    };
    const getType = (lndcgrCodeNm: string) => {
        if (matchCardType(lndcgrCodeNm) === TYPE_BUILDING)
            return isEmptyBuildingData(buildingCardContents) ? TYPE_LAND : TYPE_BUILDING;
        if (matchCardType(lndcgrCodeNm) === TYPE_LAND) return TYPE_LAND;

        return TYPE_LAND;
    };

    const fetchFieldContentsData = async () => {
        const res = await Promise.all([
            useGetFieldContents({ address, pnu }),
            useGetMapFieldInformation({
                latitude: Number(searchParams.get("lat") ?? ""),
                longitude: Number(searchParams.get("lng") ?? ""),
            }),
            useGetRoadAddressFromCoords(location),
        ]);
        const { land, building, totalBuildingTable } = res[0];
        const roadAddr = res[2];
        setLandCardContents({ ...land, position: location, address });
        setBuildingCardContents({ ...building, roadAddr, totalBuildingTable, position: location });
    };

    useEffect(() => {
        //1. 필지 클릭시 address를 카드 컴포넌트로 전달하고 토지카드 api 호출
        if (address !== "" && pnu !== "") {
            refetchAreaSize();
            Promise.all([fetchFieldContentsData()]);
        }
    }, [address, pnu]);
    useEffect(() => {
        //2. 토지카드 호출 후 landCardContents 지목명 변경시 카드 타입 정의
        if (landCardContents?.lndcgrCodeNm) {
            const GET_TYPE = getType(landCardContents?.lndcgrCodeNm);
            setCardType(GET_TYPE);
        }
    }, [landCardContents?.lndcgrCodeNm]);

    useEffect(() => {
        if (!roadAddr) return;
        setBuildingCardContents((prev: any) => {
            return { ...prev, roadAddr };
        });
    }, [roadAddr]);

    useEffect(() => {
        if (landCardContents && buildingCardContents) {
            setAllFieldData((prev: any) => {
                return {
                    ...prev,
                    land: landCardContents,
                    building: buildingCardContents,
                    pnu,
                };
            });
            setBuildingCardProps({
                titleRef,
                isBackToAppShow,
                handleClickBackToApp,
                handleClickRoadViewButton,
                hideLandCard,
                landCardContents,
                buildingCardContents,
                address,
            });
        }
    }, [landCardContents, buildingCardContents]);
    const [buildingCardProps, setBuildingCardProps] = useState({
        titleRef,
        isBackToAppShow,
        handleClickBackToApp,
        handleClickRoadViewButton,
        hideLandCard,
        landCardContents,
        buildingCardContents,
        address,
    });

    const HeaderContent = useMemo(
        () => (
            <CardBottomSheetHeader
                type={cardType}
                titleRef={titleRef}
                isBackToAppShow={isBackToAppShow}
                handleClickBackToApp={handleClickBackToApp}
                handleClickRoadViewButton={handleClickRoadViewButton}
                hideLandCard={hideLandCard}
                landCardContents={landCardContents}
                buildingCardContents={buildingCardContents}
                address={address}
                convertCardType={(type: "land" | "building") => setCardType(type)}
                fieldJimok={fieldJimok}
                areaSize={areaSize}
                fromCheck={fromCheck}
                handleClickBackToAiChat={handleClickBackToAiChat}
            />
        ),
        [allFieldData, fromCheck, fieldJimok, areaSize, isBackToAppShow, address, type, pnu],
    );

    return (
        <>
            <StyledWrapper wrapperHeight={wrapperHeight} ref={wrapperRef} css={uncheckedZindex}>
                <div css={InnerWrapper} ref={sheet}>
                    {HeaderContent}
                    <CardDataSheet
                        data={data}
                        content={content}
                        checked={checked}
                        type={cardType}
                        location={location}
                        cardData={allFieldData}
                        handleSwipDown={handleSwipDown}
                        buildingCardProps={buildingCardProps}
                        buildingSize={buildingSize}
                        fieldJimok={fieldJimok}
                        address={address}
                        areaSize={areaSize}
                        eumMapLinks={landCardContents.eum_map_obj}
                    />
                </div>
            </StyledWrapper>
        </>
    );
}
const checkedZindex = css`
    z-index: 995;
    .summCard {
        display: none;
    }
`;
const uncheckedZindex = css`
    z-index: 991;
    .summCard {
        display: block !important;
    }
`;
const StyledWrapper = styled.div<{ wrapperHeight: number }>`
    width: 100vw;
    min-height: ${(props) => props.wrapperHeight}px;
    /* height: fit-content; */
    /* height: 100vh; */
    background: white;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.08);
    border-radius: 14px 14px 0px 0px;
    position: absolute;
    left: 0;
    bottom: 0;
    /* z-index: 994; */
`;

const InnerWrapper = css`
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 1;
    left: 0;
    right: 0;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    height: fit-content;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
    transition: transform 150ms ease-out;
    /* height: 100vh; */
`;

export default Card;
