import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import ToastMessage from "@src/components/filter/ToastMessage";
import CloseIcon from "@src/assets/ico_clear_filter.svg";
import ClassificationFilter from "./ClassificationFilter";
import AssetManagementFilter from "./AssetManagementFilter";
import OwnedFilter from "./OwnedFilter";
import UseFilter from "./UseFilter";
import StateLandLocationFilter from "./StateLandLocationFilter";
import StateLandAreaFilter from "./StateLandAreaFilter";
import ConfirmModal from "@src/components/popup/ConfirmModal";
import { IUserStateLandSubs } from "@src/hooks/stateLand/useGetStateLandSubsInfo";
import { transferToNative } from "@src/lib/WebAppBridge";

interface Props {
    isShow: boolean;
    onCloseView?: () => void;
    stateLandSubs?: IUserStateLandSubs;
    setOpenStateLandFilter?: any;
}

const StateLandFilter: React.FC<Props> = ({ isShow, onCloseView, stateLandSubs, setOpenStateLandFilter }) => {
    const [toastVisible, setToastVisible] = useState<boolean>(false);
    const [showStateLandModal, setShowStateLandModal] = useState<boolean>(false);
    return (
        <>
            <div css={showRootStyle(isShow)}>
                <div css={titleBoxStyle}>
                    <p className="title">국·공유지 필터</p>
                    <img src={CloseIcon} alt="" className="closeButton" onClick={onCloseView} />
                </div>
                <p css={titleDescStyle}>국·공유지 필터는 검색 설정과 별도로 적용돼요</p>
                <div className="content">
                    <ClassificationFilter />
                    <AssetManagementFilter />
                    <OwnedFilter />
                    <UseFilter />
                    <StateLandLocationFilter
                        onSubmitShowToast={() => setToastVisible(true)}
                        stateLandSubs={stateLandSubs}
                        onSubmitShowRegionModal={() => setShowStateLandModal(true)}
                    />
                    <StateLandAreaFilter />
                </div>
                {toastVisible && (
                    <ConfirmModal
                        resultMessage={
                            <>
                                <p>소재지는 최대 10개까지</p>
                                <br />
                                <p> 선택 가능합니다.</p>
                            </>
                        }
                        onConfirm={() => setToastVisible(false)}
                    />
                )}
                {showStateLandModal && (
                    <ConfirmModal
                        resultMessage={
                            <>
                                <p>구매하지 않은 지역입니다.</p>
                                <br />
                                <p>
                                    {" "}
                                    국•공유지 조회 지역을 <br />
                                    추가하시겠습니까?
                                </p>
                            </>
                        }
                        cancelText={"닫기"}
                        buttonText={"추가하기"}
                        onConfirm={() => {
                            transferToNative("msgaapp://deeplink/product/state_land", "onClickBottomButton");
                            setShowStateLandModal(false);
                            setOpenStateLandFilter(false);
                        }}
                        onCancel={() => setShowStateLandModal(false)}
                    />
                )}
            </div>
        </>
    );
};

export default StateLandFilter;

const showRootStyle = (isShow: boolean) => css`
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100vh;
    height: 100dvh;
    width: 100vw;
    background-color: #ffffff;
    transition: all 200ms ease-in;
    visibility: ${isShow ? "visible" : "hidden"};
    opacity: ${isShow ? 1 : 0};
    & > .content {
        max-height: calc(100vh - 113px);
        max-height: calc(100dvh - 113px);
        padding-bottom: 32px;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: none;
        scrollbar-width: none;
        ::-webkit-scrollbar {
            display: none;
        }
    }
`;

const titleBoxStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.875rem;
    position: relative;
    background-color: #282828;
    & > .title {
        color: #ffffff;
        text-align: center;
        font-size: 1.125rem;
        font-weight: 700;
        line-height: 155.556%;
    }
    & > .closeButton {
        position: absolute;
        top: 50%;
        padding: 15px;
        right: -15px;
        transform: translate(-50%, -50%);
    }
`;

const titleDescStyle = css`
    color: #a3a3a3;
    text-align: center;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 153.846%;
    padding: 0.875rem 0;
`;
